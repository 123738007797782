import React, { useEffect } from 'react'
import classNames from 'classnames'
import { Typography, Tooltip } from '@material-ui/core'

import CategoryStyles from './Category.module.scss'

const Category = ({ image, caption, isActive, setActive, id, current, loading, index }) => {
  const isWindowLoaded = typeof window !== 'undefined' && window.window
  const shopCategoryImageSrc = image
    ? (`${process.env.REACT_APP_IMAGES_API_URL}/fit-in/84x84/${image}`)
    : (`https://ui-avatars.com/api/?name=${caption}&size=80&background=CAD7CA&color=fff`);
  const shopCategoryImageSrcSet = image
    ? (`${process.env.REACT_APP_IMAGES_API_URL}/fit-in/84x84/${image} 1x, 
                                    ${process.env.REACT_APP_IMAGES_API_URL}/fit-in/168x168/${image} 2x, 
                                    ${process.env.REACT_APP_IMAGES_API_URL}/fit-in/252x252/${image} 3x`)
    : (`https://ui-avatars.com/api/?name=${caption}&size=80&background=CAD7CA&color=fff 1x,
                                    https://ui-avatars.com/api/?name=${caption}&size=160&background=CAD7CA&color=fff 2x,
                                    https://ui-avatars.com/api/?name=${caption}&size=240&background=CAD7CA&color=fff 3x`);
  const currentCategoryKey = 'wa10x-active-category'

  function setActiveState() {
    // Persist current category in local storage
    if (isWindowLoaded) {
      localStorage.setItem(currentCategoryKey, id)
    }
    if(current !== id) {
      setActive(id)
    }
  }

  const categoryClass = classNames({
    [CategoryStyles.item]: true,
    [CategoryStyles.active]: current === id,
    [CategoryStyles.loading]: loading
  })

  return (
    <Tooltip title={caption} placement="top">
    <div
      tabIndex={index}
      role="button"
      className={categoryClass}
      onClick={setActiveState}
      onKeyDown={setActiveState}
      translate='no'
    >
      <div className={CategoryStyles.category}>
        {loading
          ?
          <div className={CategoryStyles.loader} />
          :
          <img
            className={CategoryStyles.image}
            srcSet={shopCategoryImageSrcSet}
            src={shopCategoryImageSrc}
            alt={caption}
          />
        }
        <svg
          className={CategoryStyles.border}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 82 82"
        >
          <circle cx="41" cy="41" r="40" />
        </svg>
      </div>
      <Typography
        variant="caption"
        className={`
          ${CategoryStyles.caption}
          ${isActive ? current === id : ''}
        `}
      >
        {caption}
      </Typography>
    </div>
    </Tooltip>
  )
}

export default Category
