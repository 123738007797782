import React from 'react'
import classNames from 'classnames/bind'

import {
  Tooltip,
  makeStyles
} from '@material-ui/core'

import { Whatsapp } from 'mdi-material-ui'

import HeaderStyles from './Header.module.scss'

import { VerifiedIcon } from '../../assets/icons/verified'

import SearchBar from './search-bar/search-bar'

const Header = ({ storeId, shopTitle, shopLogo, shopDescription, shopCover, verified, cartUrl, productSubUrl ,cartItems, shopType, loading }) => {
  const shopLogoImageSrc = shopLogo 
                             ? (`${process.env.REACT_APP_IMAGES_API_URL}/fit-in/500x500/${shopLogo}`)
                             : (`https://ui-avatars.com/api/?name=${shopTitle || `10X&length=3`}&size=90&background=266678&color=fff`);
  const shopLogoImageSrcSet = shopLogo 
                             ? (`${process.env.REACT_APP_IMAGES_API_URL}/fit-in/500x500/${shopLogo} 1x, 
                                 ${process.env.REACT_APP_IMAGES_API_URL}/fit-in/1000x1000/${shopLogo} 2x, 
                                 ${process.env.REACT_APP_IMAGES_API_URL}/fit-in/1500x1500/${shopLogo} 3x`) 
                             : (`https://ui-avatars.com/api/?name=${shopTitle || `10X&length=3`}&size=90&background=266678&color=fff 1x,
                                 https://ui-avatars.com/api/?name=${shopTitle || `10X&length=3`}&size=180&background=266678&color=fff 2x,
                                 https://ui-avatars.com/api/?name=${shopTitle || `10X&length=3`}&size=270&background=266678&color=fff 3x`);

  const shopCoverImage = shopCover ? `${process.env.REACT_APP_IMAGES_API_URL}/fit-in/1200x1200/${shopCover})` : ``;

  const bannerClass = classNames({
    [HeaderStyles.coverWrapper]: true,
    [HeaderStyles.loading]: loading
  });

  const avatarClass = classNames({
    [HeaderStyles.avatar]: true,
    [HeaderStyles.loading]: loading
  });

  return (
    <header className={HeaderStyles.header} translate='no'>
      <SearchBar 
        cartUrl={cartUrl}
        storeId={storeId}
        shopLogoImageSrc={shopLogoImageSrc}
        shopTitle={shopTitle}
        productSubUrl={productSubUrl}
        cartItems={cartItems}
      />
      <section className={bannerClass} style={{ backgroundImage: `url(${shopCoverImage}` }} />
      <section className={HeaderStyles.content}>
        <div className={HeaderStyles.shopBranding}>
          <div className={avatarClass}>
            {!loading && 
              <img
                srcSet={shopLogoImageSrcSet}
                src={shopLogoImageSrc}
                alt={shopTitle}
              />
            }
          </div>
          <div className={HeaderStyles.infoCtnr }>
            <div className={HeaderStyles.titleContainer}>
              <h1>
                {shopTitle}
              </h1>
                {!loading && verified && 
                  <VerifiedTooltip
                    title="Tienda verificada"
                    aria-label="Tienda verificada"
                    placement="right"
                    enterTouchDelay={0}
                    arrow
                    >
                    <div className={HeaderStyles.verifiedContainer}>
                      <div>
                        <VerifiedIcon className={HeaderStyles.verifiedBadgeIcon} />
                      </div>
                      <span />
                    </div>
                  </VerifiedTooltip>
                }
                <div className={HeaderStyles.whatsappBadge}>
                  <Whatsapp className={HeaderStyles.whatsappBadgeIcon} /> <span className={HeaderStyles.whatsappBadgeCaption}>{!shopType ? `Tienda WhatsApp` : `Tienda WhatsApp Mayorista`}</span>
                </div>
            </div>
            <div className={HeaderStyles.descriptionContainer}>
              <p >
                {shopDescription}
              </p>
            </div>
          </div>
        </div>
        <div className={HeaderStyles.mobileDes}>
          <div className={HeaderStyles.whatsappBadgeMobile}>
            <Whatsapp className={HeaderStyles.whatsappBadgeIcon} /> <span className={HeaderStyles.whatsappBadgeCaption}>{!shopType ? `Tienda WhatsApp` : `Tienda WhatsApp Mayorista`}</span>
          </div>
          <p >
            {shopDescription}
          </p>
        </div>
      </section>
    </header>
  )
}

// Customize the VerifiedTooltip Component
function VerifiedTooltip(props) {
  const classes = verifiedTooltipStyles()
  return (
    <Tooltip arrow classes={classes} {...props} />
  )
}

// Material-UI Styles
const verifiedTooltipStyles = makeStyles(theme => ({
  popper: {
    marginLeft: '-20px',
  },
}));

export default Header
