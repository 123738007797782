/* eslint-disable import/first */
// react dependencies
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, useParams } from 'react-router-dom';

// external dependencies
import { useSnackbar } from 'notistack';
import difference from 'lodash/difference';
import { hotjar } from 'react-hotjar';
import axios from 'axios';
import classNames from 'classnames/bind';

//Pixel FACEBOOK
import ReactPixel from 'react-facebook-pixel';

//Google Analitycs
import ReactGA from 'react-ga';

// material dependencies
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core'

// assets
import Minus from '../Icons/Minus'
import Plus from '../Icons/Plus'
import ShoppingBag from '../Icons/ShoppingBag'
import Trash from '../Icons/Trash'
import CreditCard from '../Icons/CreditCard'
import emptyCartImage from '../../assets/images/emptyCart.png'
import { DeliveryMotor } from '../../assets/icons/motorbike'
import { ShopIcon } from '../../assets/icons/shop'

// styles
import CartStyles from './Cart.module.scss';

// state
import { setCartState } from '../../modules/main';
import { cleanShoppingCart, formatNumber } from '../../utils/constants';
import { getProductCartInventory } from './../../services/inventory';
import SearchBar from '../Header/search-bar/search-bar';
import { getCities, getPaymentChannels, getShippingDestination, getShippingInfo } from '../../services/payments'
import { IconCashDelivery } from '../../assets/icons/cash-on-delvery';

/**
 * 
 * @param {Array} items cart items from redux
 * @param {Object} store store data
 * @param {Function} setCartState function to save the cart in localStorage
 */
const Cart = ({ items, store, setCartState }) => {
  const history = useHistory();
  const { /* storeUrl, */ phoneNumber } = useParams();
  const storeUrl = window.location.hostname.split(".")[0]
  const { enqueueSnackbar } = useSnackbar();

  // state variables
  const [beforeSendOrderLoading, setBeforeSendOrderLoading] = useState(false)
  const [newOptionQuantity, setNewOptionQuantity] = useState(1);
  const [showMinimumCartValue, setShowMinimumCartValue] = useState(false)
  const [destinations, setDestinations] = useState([])
  const [shippingInfo, setShippingInfo] = useState(null)
  const [shippingCity, setShippingCity] = useState(null)
  const [shippingMethod, setShippingMethod] = useState('Domicilio')
  const [selectedBranch, setSelectedBranch] = useState(null)
  const [allCities, setAllCities] = useState([])
  const [symbolsArr] = useState(["e", "E", "+", "-", "."])
  const [paymentMethods, setPaymentMethods] = useState([])
  const [isWhatsApp, setIsWhatsApp] = useState([])
  const [loading, setLoading] = useState(false)
  const [restCountry, setRestCountry] = useState(false)
  const [textCity, setTextCity] = useState('')
  // extendedItems is a copy of the items array
  const [extendedItems, setExtendedItems] = useState([])

  const onlinePaymentMethods = paymentMethods?.filter( method => method.payment_channel_id !== '7' )
 
  const minimumCartValueRule = store.wholesaleRules.find(rule => rule.wholesaleRuleType.id == 1);
  const minimumCartValue = minimumCartValueRule ? minimumCartValueRule.minimumCartValue : -1;
  
  // ref for the new option form
  const formRef = useRef(null);
  
  /**
   * redirects to the store page
  */
 const nameCollection = history.location.search.slice(5)
 const goHome = () => history.push(`/${phoneNumber}/collections/${nameCollection ? nameCollection : ''}`)

  /**
   * clean shopping cart
   */
  const deleteAllCart = () => {
    cleanShoppingCart()
    setExtendedItems([])
  };

  /**
   * Shows/Hides the more options form for a product
   * @param {boolean} visible whether or not to show the new option form
   * @param {number} index item index
   */
  const toggleItemOptionsVisibility = (visible, index) => {
    setExtendedItems(extendedItems.map((item, itemIndex) => ({
      ...item,
      showMoreOptions: index === itemIndex ? visible : item.showMoreOptions
    })))
  }

  /**
   * updates the amount of a product
   * @param {number} index product index
   * @param {boolean} add increase/decrease amount
   */
  const updateProductAmount = (index, add = true) => {
    setExtendedItems(extendedItems.map((item, itemIndex) => {
      const productListDiscountPrice = calculateDiscount({
        ...item,
        amount: add ? item.amount + 1 : item.amount - 1
      });

      if (index === itemIndex) {
        return {
          ...item,
          amount: add ? item.amount + 1 : item.amount - 1,
          productListDiscountPrice: productListDiscountPrice ? productListDiscountPrice : undefined
        }
      }

      return { ...item }
    })
      .filter(item => item.amount > 0 || item.extraVariants.length > 0));
  }

  /**
   * Removes a product from the cart
   * @param {number} index product index
   */
  const deleteCartItem = (index) => setExtendedItems(
    extendedItems.filter((itemExtra, itemIndex) => itemIndex !== index)
  );

  /**
   * Sets the amount for the new variant to add inside the more options form
   * @param {boolean} add increase/decrease amount
   */
  const updateNewItemQuantity = add => {
    const newQuantity = add ? newOptionQuantity + 1 : newOptionQuantity - 1;
    if (newQuantity >= 1) setNewOptionQuantity(newQuantity);
  }

  /**
   * Adds a new variant to a product
   * @param {event} e form event
   * @param {object} item product data object 
   * @param {number} index product index 
   */
  const addNewVariant = (e, item, index) => {
    e.preventDefault(); // prevent for submition
    // selects inside the form are uncontrolled, get the values
    const selects = Array.from(formRef.current.querySelectorAll('select'));
    // force the user to set a value for all selects inside the form
    if (selects.filter(select => select.value).length !== selects.length) {

      return enqueueSnackbar('Selecciona todas las opciones', {
        variant: 'warning',
        autoHideDuration: 3000
      });
    }
    // find selected variant
    const selectedVariant = item.variants.find(variant => {

      return difference(selects.map(s => s.value), variant.optionValues.map(value => value.id)).length === 0;
    });

    // push new variant to the extraVariants array 
    setExtendedItems(extendedItems.map((extendedItem, extendedItemIndex) => {
      if (extendedItemIndex === index) {

        // check if variant is the one the user selected on the product page
        const isInitialVariant = extendedItem.amount > 0 &&
          JSON.stringify(extendedItem.productOptions.map(o => o.selected)) ===
          JSON.stringify(selects.map(s => s.value));

        // check if variant is already pushed
        const alreadyPushedIndex = extendedItem.extraVariants.findIndex(extraVariant => {
          return JSON.stringify(selects.map(s => s.value)) === JSON.stringify(extraVariant.options);
        });

        const updatedExtraVariants = extendedItem.extraVariants.map((extraVariant, extraVariantIndex) => {
          if (alreadyPushedIndex === extraVariantIndex) {
            return {
              ...extraVariant,
              amount: extraVariant.amount + newOptionQuantity,
              active: 1
            }
          }

          return { ...extraVariant }
        });

        const extraVariantsWithNewItem = [...extendedItem.extraVariants, {
          sku: selectedVariant ? selectedVariant.sku || extendedItem.sku : extendedItem.sku,
          variantId: selectedVariant ? selectedVariant.id : '',
          price: selectedVariant ? item.productListDiscountPrice || selectedVariant.discountPrice || selectedVariant.price || item.discountPrice || item.originalPrice : item.productListDiscountPrice || item.discountPrice || item.originalPrice,
          amount: newOptionQuantity,
          active: 1,
          options: selects.map(s => s.value)
        }];

        const newVariants = isInitialVariant ? extendedItem.extraVariants : (alreadyPushedIndex >= 0 ? updatedExtraVariants : extraVariantsWithNewItem);
        const variantsPrice = newVariants.reduce((prev, current) => prev + (current.price * current.amount), 0);

        return {
          ...extendedItem,
          amount: isInitialVariant ? extendedItem.amount + newOptionQuantity : extendedItem.amount,
          extraVariants: newVariants,
          variantsPrice,
          showMoreOptions: false,
          active: 1,
        }
      }
      return {
        ...extendedItem
      }
    }));
  }

  /**
   * Updates the amount of an extra variant
   * @param {number} productIndex product index
   * @param {number} extraVariantIndex extra variant index
   * @param {boolean} add increase/decrease extra variant amount
   */
  const updateExtraVariantAmount = (productIndex, extraVariantIndex, add) => {
    setExtendedItems(extendedItems.map((extendedItem, extendedItemIndex) => {
      if (extendedItemIndex === productIndex) {
        const updatedExtraVariants = extendedItem.extraVariants.map((extraVariant, variantIndex) => {
          if (variantIndex === extraVariantIndex) {

            return {
              ...extraVariant,
              amount: add ? extraVariant.amount + 1 : extraVariant.amount - 1
            }
          }
          return { ...extraVariant }
        }).filter(extraVariant => extraVariant.amount > 0);

        const variantsPrice = updatedExtraVariants.reduce((prev, current) => prev + (current.price * current.amount), 0);

        return {
          ...extendedItem,
          extraVariants: updatedExtraVariants,
          variantsPrice
        }
      }

      return {
        ...extendedItem
      }
    })
      .filter(item => item.amount > 0 || item.extraVariants.length > 0));
  }

  const arrayOfVariants = () => {
    let variants = [];
    let totalVariants = [];
    extendedItems.forEach(item => {
      const { amount, extraVariants, variantId, id } = item;

      const selectedId = variantId !== null ? { id: variantId, quantity: amount, is_variant: true } : { id, quantity: amount, is_variant: false };

      variants = extraVariants.length > 0
        ? [selectedId, ...extraVariants?.map(extV => ({ id: extV.variantId, quantity: extV.amount, is_variant: true }))]
        : [selectedId];

      totalVariants = [...totalVariants, ...variants];
      return variants
    });

    return totalVariants;
  }

  const getNameVariant = (idV, isVariant) => {
    let variantsArr = [];
    let nameVariant = '';
    let names = null;

    extendedItems.forEach(item => {
      const { variants, variantId, id, name } = item;
      isVariant ? variantsArr = variants : variantsArr = [{ id, name }];

      if (isVariant) {
        variantsArr.forEach(variant => {
          const { optionValues, id } = variant;
          if (id === idV) {
            nameVariant = optionValues.map(value => value.name).join(' ');
            names = { nameProduct: name, nameVariant }
          }
        });
      } else {
        nameVariant = variantsArr[0].name;
        names = { nameProduct: name, nameVariant }
      }
    });

    return names || { nameProduct: '', nameVariant: '' };
  }

  const handleShippingMethod = (met) => {
    if(met === 'Domicilio') {
      setShippingMethod('Domicilio')
      setSelectedBranch(null)
    }
    else if(met === 'Recoger en tienda'){
      setShippingMethod('Recoger en tienda')
      setShippingCity(null)
      setRestCountry(false)
      setTextCity('')
    }
  }

  const calculateDiscount = product => {
    const volumeDiscountRule = store.wholesaleRules.find(rule => rule.wholesaleRuleType.id == 2);
    let productListDiscountPrice;

    if (volumeDiscountRule) {
      const productRules = volumeDiscountRule.wholesaleRuleProducts.filter(rule => rule.product.id === product.id);
      if (productRules) {
        // check if the rule is fulfilled
        const totalVariantsAmount = product.extraVariants.reduce((prev, current) => prev + current.amount, 0);
        const totalProductAmount = product.amount + totalVariantsAmount;
        const totalAmount = totalVariantsAmount + totalProductAmount;
        const totalPrice = product.originalPrice * totalAmount;

        const compare = (a, b) => {
          if (a.min < b.min) return -1
          if (a.min > b.min) return 1
          return 0;
        }

        // sort product rules
        const sortedRules = productRules.map(rule => ({ ...rule })).sort(compare);

        sortedRules.forEach(rule => {
          if (rule.type === "Units") {
            if (totalAmount >= rule.min) {
              productListDiscountPrice = rule.percentage ? product.originalPrice - (product.originalPrice * rule.value / 100) : rule.value;
            }
          }

          if (rule.type === "Total") {
            if (totalPrice >= rule.min) {
              productListDiscountPrice = rule.percentage ? product.originalPrice - (product.originalPrice * rule.value / 100) : rule.value;
            }
          }
        })

      }
    }

    return productListDiscountPrice;
  }

  const handleChange = (e, index) => {
    e.preventDefault()
    setExtendedItems(extendedItems.map((item, itemIndex) => {
      const productListDiscountPrice = calculateDiscount({
        ...item,
        amount: Number(e.target.value)
      });
      if (index === itemIndex) {
        return {
          ...item,
          amount: Number(e.target.value),
          productListDiscountPrice: productListDiscountPrice ? productListDiscountPrice : undefined
        }
      }

      return { ...item }
    })
      .filter(item => item.amount > 0 || item.extraVariants.length > 0));
  }

  const findCityName = (id) => {
    const c = allCities?.find( ct => ct.id === id )
    return c?.name
  }

  const validateShippingInfo = () => {
    if(shippingInfo?.shipping_type_id === 3) {
      const v = selectedBranch === null ? false : true
      const c = shippingCity === null ? false : true
      const x = textCity.length >= 3 ? true : false
      if(v || c || x) {
        return false
      }else{
        return true
      }
    }else if(shippingInfo?.shipping_type_id !== 3 && shippingMethod === 'Recoger en tienda'){
        const v = selectedBranch === null ? true : false
        return v
    }

    return false
  }

  const validateUponDelivery  = () => {
    if(shippingInfo?.shipping_type_id === 3) {
      if(shippingMethod === 'Recoger en tienda') return true
      if(shippingMethod === 'Domicilio'){
        const c = shippingCity === null ? true : false
        
        return c
      }
      
    }else{
      if(shippingMethod === 'Recoger en tienda') return true
    }

    return false
  }

  const handleDestinationChange = (e) => { 
    const selectedCity = e.target.value
    if (selectedCity === 'otra') {
      setRestCountry(true)
      setShippingCity(null)
    }else{
      const selectedDestinationObject = destinations.find((des) => des.city === selectedCity)
      setShippingCity(selectedDestinationObject)
      setTextCity('')
      restCountry && setRestCountry(false)
    }
  }

  const handleChangeExtraOptions = (e, productIndex, extraVariantIndex) => {
    setExtendedItems(extendedItems.map((extendedItem, extendedItemIndex) => {
      if (extendedItemIndex === productIndex) {
        const updatedExtraVariants = extendedItem.extraVariants.map((extraVariant, variantIndex) => {
          if (variantIndex === extraVariantIndex) {

            return {
              ...extraVariant,
              amount: Number(e.target.value),
            }
          }
          return { ...extraVariant }
        }).filter(extraVariant => extraVariant.amount > 0);

        const variantsPrice = updatedExtraVariants.reduce((prev, current) => prev + (current.price * current.amount), 0);

        return {
          ...extendedItem,
          extraVariants: updatedExtraVariants,
          variantsPrice
        }
      }

      return {
        ...extendedItem
      }
    }).filter(item => item.amount > 0 || item.extraVariants.length > 0));
  }

  const removeExtraVariant = (productIndex, extraVariantIndex) => {
    const whitAmount = extendedItems.map((extendedItem, extendedItemIndex) => {
      if (extendedItemIndex === productIndex) {
        const updatedExtraVariants = extendedItem.extraVariants.map((extraVariant, variantIndex) => {
          if (variantIndex === extraVariantIndex) {
            
            return {
              ...extraVariant,
              amount: 0
            }
          }
          return { ...extraVariant }
        }).filter(extraVariant => extraVariant.amount > 0)

        const variantsPrice = updatedExtraVariants.reduce((prev, current) => prev + (current.price * current.amount), 0)

        return {
          ...extendedItem,
          extraVariants: updatedExtraVariants,
          variantsPrice
        }
      }
      return { ...extendedItem }
    }) 
    const whitNoAmount = whitAmount.filter(item => item?.amount > 0)
    setExtendedItems(whitNoAmount)
  }

  const totalWithShipping = () => {
    if(shippingInfo?.shipping_type_id === 2){
      if(selectedBranch){
        return 0
      }else{
        return isNaN(shippingInfo?.delivery_cost_details) ? 0 : parseInt(shippingInfo?.delivery_cost_details)
      }
    }
    else if (shippingInfo?.shipping_type_id === 3){
      if(shippingCity) {
        return shippingCity?.shipping_price ? shippingCity?.shipping_price : 0
      }
      else if(textCity.length >= 3) {
        return shippingInfo?.shipping_destination?.price_other_zones ? shippingInfo?.shipping_destination?.price_other_zones : 0
      }
      else{
        return 0
      }
    }else{
      return 0
    }
  }
  const totalForProps = formatNumber(extendedItems.reduce((prev, current) => prev + ((current.productListDiscountPrice || current.price) * current.amount) + current.variantsPrice, 0) + totalWithShipping(), store.currency)

  const totalPay = extendedItems.reduce((prev, current) => prev + ((current.productListDiscountPrice || current.price) * current.amount) + current.variantsPrice, 0) + totalWithShipping()

  const shippingPriceFormat = formatNumber(totalWithShipping(), store.currency)
  const shippingCityFormat = formatNumber(shippingCity?.shipping_price, store.currency)
  const shippingOtherCityFormat = formatNumber(shippingInfo?.shipping_destination?.price_other_zones, store.currency)

  const goToSendOrder = (ce) => {
    const findBranch = selectedBranch ? shippingInfo?.collections_points.find( el => el.id === selectedBranch) : ''
    const shipName = shippingMethod === 'Domicilio' && shippingCity?.city ? shippingCity?.city : textCity
    const pickName = shippingMethod === 'Recoger en tienda' ? findCityName(findBranch?.city_id) : ''
    const delCityId = shippingCity?.city && allCities?.find( ct => ct.name === shippingCity?.city )
    const shipCity = shipName ? shipName : pickName
  
    const shippingDataStorage = {
      shipping_method: shippingMethod ? shippingMethod : 'Domicilio',
      sel_city_id: delCityId ? delCityId.id : findBranch?.city_id ? findBranch?.city_id : null,
      delivery_city: shipCity, 
      branch: findBranch,
      cash_on_delivery: ce === 'ce' ? true : false,
      shipping_cost: totalWithShipping()
    }
    if (extendedItems.length === 0) {
      return enqueueSnackbar('Agrega productos al carrito', {
        variant: 'warning',
        autoHideDuration: 3000
      });
    }

    const variantsToCheck = arrayOfVariants()
      getProductCartInventory(store.url, variantsToCheck)
      .then(res => {
        const shippingObj = JSON.stringify(shippingDataStorage)
        localStorage.setItem('deliveryInfo', shippingObj)
        localStorage.setItem('totalForProps', totalForProps)
        localStorage.setItem('totalPay', totalPay)
        history.push(`/${phoneNumber}/order/create`)
      })
      .catch(err => {
        if (err.response.status === 400) {
          // Handle 400 error
          if (err.response.data.length > 0) {
            const data = err.response.data;
            data.forEach(product => {
              const { id, quantity, is_valid, is_variant } = product;
              if (!is_valid) {
                const namesProduct = getNameVariant(id, is_variant);
                const message = is_variant
                  ? `Lo sentimos, no tenemos disponible la cantidad de la variación ${namesProduct?.nameVariant} del producto ${namesProduct?.nameProduct}`
                  : `Lo sentimos, no tenemos disponible la cantidad del producto ${namesProduct?.nameProduct}`;
                return enqueueSnackbar(message, {
                  variant: 'error',
                  autoHideDuration: 3000
                });
              }
            });
          }
        }
      });
      ReactPixel.track('Lead', {
        content_name: 'terminando carrito',
        value: totalForProps,
        currency: store.currency,
      });
      ReactPixel.track('CompleteRegistration') 
  }
  const goToSendOnlineOrder = () => {
    if (extendedItems.length === 0) {
      return enqueueSnackbar('Agrega productos al carrito', {
        variant: 'warning',
        autoHideDuration: 3000
      });
    }
    const findBranch = selectedBranch ? shippingInfo?.collections_points.find( el => el.id === selectedBranch) : ''
    const shipName = shippingMethod === 'Domicilio' && shippingCity?.city ? shippingCity?.city : textCity
    const pickName = shippingMethod === 'Recoger en tienda' ? findCityName(findBranch?.city_id) : ''
    const delCityId = shippingCity?.city && allCities?.find( ct => ct.name === shippingCity?.city )
    const shipCity = shipName ? shipName : pickName
  
    const shippingDataStorage = {
      shipping_method: shippingMethod ? shippingMethod : 'Domicilio',
      sel_city_id: delCityId ? delCityId.id : findBranch?.city_id ? findBranch?.city_id : null,
      delivery_city: shipCity, 
      branch: findBranch,
      cash_on_delivery: false,
      shipping_cost: totalWithShipping()
    }
    const variantsToCheck = arrayOfVariants()
      getProductCartInventory(store.url, variantsToCheck)
      .then(res => {
        const shippingObj = JSON.stringify(shippingDataStorage)
        localStorage.setItem('deliveryInfo', shippingObj)
        localStorage.setItem('totalForProps', totalForProps)
        localStorage.setItem('totalPay', totalPay)
        history.push(`/${phoneNumber}/order-online/create`) 
      } )
      .catch(err => {
        if (err.response.status === 400) {
          // Handle 400 error
          if (err.response.data.length > 0) {
            const data = err.response.data;
            data.forEach(product => {
              const { id, quantity, is_valid, is_variant } = product;
              if (!is_valid) {
                const namesProduct = getNameVariant(id, is_variant);
                const message = is_variant
                  ? `Lo sentimos, no tenemos disponible la cantidad de la variación ${namesProduct?.nameVariant} del producto ${namesProduct?.nameProduct}`
                  : `Lo sentimos, no tenemos disponible la cantidad del producto ${namesProduct?.nameProduct}`;
                return enqueueSnackbar(message, {
                  variant: 'error',
                  autoHideDuration: 3000
                });
              }
            });
          }
        }
      });
      ReactPixel.track('Lead', {
        content_name: 'terminando carrito',
        value: totalForProps,
        currency: store.currency,
      });
      ReactPixel.track('CompleteRegistration') 
  }
  /* EFFECTS HERE ⬇️ */

  useEffect(() => {
    setCartState(storeUrl, phoneNumber, extendedItems, store);
    const totalOrder = extendedItems.reduce((prev, current) => prev + ((current.productListDiscountPrice || current.price) * current.amount) + current.variantsPrice, 0);
    const totalTooLow = totalOrder < minimumCartValue;
    setShowMinimumCartValue(totalTooLow);
    localStorage.setItem('totalForProps', totalForProps)
  }, [extendedItems])

  useEffect(() => setExtendedItems(items.map(item => {
    const productListDiscountPrice = calculateDiscount(item);
    let variantsPrice = item.extraVariants.reduce((prev, current) => prev + (current.price * current.amount), 0)

    return {
      ...item,
      variantsPrice: variantsPrice,
      price: item.price,
      ...productListDiscountPrice && {
        productListDiscountPrice
      }
    }

  })), []);

  useEffect(() => {
    setLoading(true)
    getPaymentChannels(store.id)
    .then((response) => {
      setPaymentMethods(response?.data)
      setIsWhatsApp(response?.data.filter(item => item?.payment_channel?.name === 'Whatsapp'))
    })
    .catch(err =>  console.log(err))

    getShippingDestination(store.id) 
    .then( res =>  setDestinations(res.data) )
    .catch( error =>  console.log(error) )

    getShippingInfo(store.id) 
    .then( res => {
     setShippingInfo(res.data) 
     setLoading(false)
    } )
    .catch( error => {
      console.log(error)
      setLoading(false)
    } )

    getCities()
    .then( res =>  setAllCities(res.data) )
    .catch( error =>  console.log(error) )

  }, [store])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      hotjar.initialize(1702922, 6);
      /* Google Analytics */
      if (store?.googleAnalitycsTrackingId) {
        ReactGA.initialize([
          {
            trackingId: store?.googleAnalitycsTrackingId
          },
          {
            trackingId: 'UA-163783687-1'
          }
        ]);
      }
      else {
        ReactGA.initialize([
          {
            trackingId: 'UA-163783687-1'
          }
        ])
      }
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
      /* Facebook Pixel */
      store?.facebookPixelCode && ReactPixel.init(store?.facebookPixelCode);
      store?.facebookPixelCode && ReactPixel.pageView()
      ReactPixel.track('AddToCart', {
        content_name: 'iniciando carrito',
        value: totalForProps,
        currency: store.currency,
      })
    }
  }, [])
  
  /* EFFECTS HERE ⬆️ */
  const base64image = (image) => {
    const image_src = JSON.stringify({
      "bucket": "wa10x.v2",
      "key": `${image.split('/')[6]}`,
      "edits": {
        "resize": {
          "width": 96,
          "height": 96,
          "fit": "cover"
        },
        "rotate": null,
      }
    })
    return image_src;
  }
 
  const base64image2 = (image) => {
    const image_src = JSON.stringify({
      "bucket": "wa10x.v2",
      "key": `${image.split('/')[6]}`,
      "edits": {
        "resize": {
          "width": 192,
          "height": 192,
          "fit": "cover"
        },
        "rotate": null,
      }
    })
    return image_src;
  }

  const base64image3 = (image) => {
    const image_src = JSON.stringify({
      "bucket": "wa10x.v2",
      "key": `${image.split('/')[6]}`,
      "edits": {
        "resize": {
          "width": 288,
          "height": 288,
          "fit": "cover"
        },
        "rotate": null,
      }
    })
    return image_src;
  }
  return (
    <div style={{ backgroundColor: '#f3f6f4' }}>
      <SearchBar 
        storeId={store.id}
        shopLogoImageSrc={`${process.env.REACT_APP_IMAGES_API_URL}/fit-in/500x500/${store.profilePicture}`}
        shopTitle={store.name}
        productSubUrl={`/${phoneNumber}`}
        cartItems={true}
        goBackToStoreUrl={`/${phoneNumber}/collections/${nameCollection ? nameCollection : ''}`}
      />
      <main className={CartStyles.cartContainer}>
        <section className={CartStyles.topHeader} translate='no'>
          <h1> carrito</h1>
            {extendedItems.length !== 0 && 
              <div onClick={deleteAllCart}>
                <Trash fontSize="small"/> 
                <p>Vaciar carrito</p>
              </div>
            }
        </section>
        <article className={CartStyles.asidesContainer}>
          {extendedItems.length <= 0 
            ? <section className={CartStyles.cartEmpty} translate='no'>
                <img src={emptyCartImage} alt='Empty cart image' />
                  <p>Aún no has añadido productos a tu carrito</p>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={goHome}
                  >
                    Explorar productos
                  </Button>
            </section>
            : <aside className={CartStyles.asideLeft}>
              {extendedItems.map((item, index) => (
                <aside className={CartStyles.cardItem} key={item.id}>
                  <div className={CartStyles.cardItemProduct}>
                    {item?.image &&
                      <img className={CartStyles.imageProduct}
                        src={`${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image(item.image)).toString('base64')}`}
                        srcSet={(`${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image(item.image)).toString('base64')} 1x,
                        ${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image2(item.image)).toString('base64')} 2x,
                        ${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image3(item.image)).toString('base64')} 3x`)}
                        alt={item.name}
                      />
                    }
                    <div className={CartStyles.productInfo}>
                        <h2>
                          {item.name}
                        </h2>
                          {item.productOptions.map(productOption => (
                            <div className={CartStyles.nameAndVariant}>
                                <span>{productOption.name}</span>
                                <p>
                                  {productOption.productOptionValues
                                    .find(option => option.id === productOption.selected)
                                    .name
                                  }
                                </p>
                              </div>
                          ) ) }
                        <span className={CartStyles.subTotalProduct}>
                          <p>SubTotal producto:</p>
                          <h6>
                            {item.originalPrice > 0 && formatNumber(((item.productListDiscountPrice || item.price) * item.amount), store.currency)}
                          </h6>
                        </span>
                    </div>
                    
                    <div className={CartStyles.actionsCardContainer}>
                      <div
                        onClick={() => deleteCartItem(index)}
                        className={CartStyles.removeProduct}
                      >
                          <Trash />
                      </div>
                      <div
                        className={CartStyles.optionButtonGroup}
                        data-tooltip={"Puedes escribir la cantidad que deseas aquí"}
                      >
                      <IconButton
                          className={CartStyles.optionButtonIcon}
                          onClick={() => updateProductAmount(index, false, item.id) }
                          variant="outlined"
                          edge="start"
                          size="small"
                          aria-label="Disminuir una unidad"
                          disabled={item.amount < 2}
                        >
                          <Minus className={CartStyles.optionButtonIconSvg} />
                        </IconButton>
                        <input
                          className={CartStyles.inputAmount}
                          onChange={(e) => {
                            if (e.target.value == 0 || e.target.value == 'e') {
                              e.target.value = 1
                            } else {
                              e.target.value = e.target.value
                            }
                            handleChange(e, index)
                          }}
                          type="text"
                          placeholder={item.amount}
                          value={item.amount}
                          onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                        />
                        <IconButton
                          className={CartStyles.optionButtonIcon}
                          onClick={() => updateProductAmount(index, true, item.id)}
                          variant="outlined"
                          edge="start"
                          size="small"
                          aria-label="Aumentar una unidad">
                          <Plus className={CartStyles.optionButtonIconSvg} />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                  <>
                          {item.productOptions.map(productOption => (
                            <div className={CartStyles.nameAndVariantMobile}>
                                <span>{productOption.name}</span>
                                <p>
                                  {productOption.productOptionValues
                                    .find(option => option.id === productOption.selected)
                                    .name
                                  }
                                </p>
                              </div>
                          ) ) }
                        <span className={CartStyles.subTotalProductMobile}>
                          <p>SubTotal producto:</p>
                          <h6>
                            {item.originalPrice > 0 && formatNumber(((item.productListDiscountPrice || item.price) * item.amount), store.currency)}
                          </h6>
                        </span>
                    </>
                  {item.extraVariants.map((extraOption, extraOptionIndex) => (
                    (
                      <div style={{ width: '100%', position: 'relative' }}>
                        <div className={CartStyles.variant}>
                        <hr className={CartStyles.divider}/>
                          {item.productOptions.map((productOption, productOptionIndex) =>
                                <div className={CartStyles.variantOption}>
                                    <span>{productOption.name}</span>
                                    <p> {productOption.productOptionValues
                                      .find(option => option.id === extraOption.options[productOptionIndex])
                                      .name
                                    }</p>
                                  </div>
                              )}
                            <span className={CartStyles.subTotalProductOption} style={{ width: '67%' }}>
                              <p>SubTotal producto:</p>
                              <h6>
                                {formatNumber(extraOption.price * extraOption.amount)}
                              </h6>
                            </span>
                          <div>
                            <div
                                className={CartStyles.optionButtonGroup}
                                data-tooltip={extraOption.active > 0 ? "Puedes escribir la cantidad que deseas aquí" : "¿Deseas eliminar el producto?"}
                              >
                              
                                  <IconButton
                                    className={CartStyles.optionButtonIcon}
                                    onClick={() => updateExtraVariantAmount(index, extraOptionIndex, false)}
                                    variant="outlined"
                                    edge="start"
                                    size="small"
                                    aria-label="Disminuir una unidad"
                                    disabled={extraOption.amount < 2}
                                  >
                                    <Minus className={CartStyles.optionButtonIconSvg} />
                                  </IconButton>
                                  <input
                                    className={CartStyles.inputAmount}
                                    onChange={(e) => {
                                      if (e.target.value == 0) {
                                        e.target.value = 1
                                      } else {
                                        e.target.value = e.target.value
                                      }
                                      handleChangeExtraOptions(e, index, extraOptionIndex)
                                    }}
                                    type="number"
                                    placeholder={extraOption.amount}
                                    value={extraOption.amount}
                                    onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                  />
                                  <IconButton
                                    className={CartStyles.optionButtonIcon}
                                    onClick={() => updateExtraVariantAmount(index, extraOptionIndex, true)}
                                    variant="outlined"
                                    edge="start"
                                    size="small"
                                    aria-label="Aumentar una unidad">
                                    <Plus className={CartStyles.optionButtonIconSvg} />
                                  </IconButton>
                            </div>
                          </div>
                        </div>
                        <div
                          onClick={() => removeExtraVariant(index, extraOptionIndex)}
                          className={CartStyles.removeProductOption}
                        >
                            <Trash />
                        </div>
                      </div>
                    )
                  ))}

                  {item.showMoreOptions &&
                    <form
                      ref={formRef}
                      className={CartStyles.moreOptionsContainer}
                      onSubmit={e => addNewVariant(e, item, index)}
                    >
                    <hr className={CartStyles.divider}/>
                      <div className={CartStyles.CurrentOptions}>
                        {item.productOptions.map(productOption =>
                          <select className={CartStyles.optionsSelect}>
                            <option value="">{productOption.name}</option>
                            {productOption.productOptionValues.map(poValue =>
                              <option value={poValue.id}>{poValue.name}</option>
                            )}
                          </select>
                        )}
                        <div className={CartStyles.optionButtonGroup}>
                          <IconButton
                            className={CartStyles.optionButtonIcon}
                            onClick={() => updateNewItemQuantity(false)}
                            variant="outlined"
                            edge="start"
                            size="small"
                            aria-label="Disminuir una unidad">
                            <Minus className={CartStyles.optionButtonIconSvg} />
                          </IconButton>
                          <Typography
                            className={CartStyles.optionQuantity}
                            variant="body2"
                            color={`${(newOptionQuantity > 0 ? 'initial' : 'textSecondary')}`}>
                            {newOptionQuantity}
                          </Typography>
                          <IconButton
                            className={CartStyles.optionButtonIcon}
                            onClick={() => updateNewItemQuantity(true)}
                            variant="outlined"
                            edge="start"
                            size="small"
                            aria-label="Aumentar una unidad">
                            <Plus className={CartStyles.optionButtonIconSvg} />
                          </IconButton>
                        </div>
                      </div>
                      <div className={CartStyles.moreOptionsButtons}>
                        <Button
                          style={{ fontSize: 16 }}
                          type="button"
                          size="small"
                          onClick={() => toggleItemOptionsVisibility(false, index)}>Cancelar</Button>
                        <Button
                        style={{ fontSize: 16, padding: '5px 20px'}}
                          type="submit"
                          color="primary"
                          size="small"
                          variant="contained">Añadir opcion</Button>
                      </div>
                    </form>
                  }
                  {(item.productOptions.length > 0 && !item.showMoreOptions) && (
                    <>
                      <hr className={CartStyles.divider}/>
                      <span
                        className={CartStyles.addVariations}
                        onClick={() => toggleItemOptionsVisibility(true, index)}
                      >
                        <Plus className={CartStyles.addVariationsButtonIcon} />
                        <p className={CartStyles.addVariationsButtonCaption} >
                          Añadir más opciones
                        </p>
                      </span>
                      
                    </>
                  )}
                </aside>
              ) ) }
              <Backdrop style={{ zIndex: 1400 }} open={beforeSendOrderLoading}>
                <CircularProgress />
              </Backdrop>
            </aside>
          }
          { loading 
          ? <div style={{ width: '40%', textAlign: 'center' }}>
              <CircularProgress />
            </div>
          : <aside className={CartStyles.summaryPurchase}>
              <h2>Resumen de tu compra</h2>
              { shippingInfo?.store_pickup_enabled &&
                <section className={CartStyles.selectCity}>
                  <label>Selecciona un método de envío</label>
                  <div className={CartStyles.typeDelivery} style={ shippingMethod ==='Domicilio' ? {border: '2px solid #173300', backgroundColor: '#9EE87033', marginBottom: 15}: {marginBottom: 15}}>
                    <input 
                      type='radio'
                      onChange={() => handleShippingMethod('Domicilio')}
                      checked={shippingMethod === 'Domicilio'}

                    />
                    <DeliveryMotor />
                    <p>Domicilio</p>
                  </div>
                  <div className={CartStyles.typeDelivery} style={shippingMethod ==='Recoger en tienda' ? {border: '2px solid #173300', backgroundColor: '#9EE87033'} : {} }>
                    <input 
                      type='radio'
                      onChange={() => handleShippingMethod('Recoger en tienda')}
                      checked={shippingMethod === 'Recoger en tienda'}
                    />
                    <ShopIcon />
                    <p>Recoger en tienda</p>
                  </div>
                </section>
              } 
              { shippingMethod === 'Domicilio' && shippingInfo?.shipping_type_id === 3 &&
                <section className={CartStyles.selectCity}>
                  <label>Ciudad de entrega</label>
                  <select onChange={handleDestinationChange}>
                    <option value="">Selecciona una ciudad</option>
                    <hr/>
                    { destinations?.map( des => (
                        <option key={des.city} value={des.city}>
                          {des.city}
                        </option>
                    ) ) }
                    { shippingInfo?.shipping_destination?.other_zones &&
                      <>
                        <hr/>
                        <option value='otra'>Otra</option>
                      </>
                    }
                    
                  </select>
                  { restCountry &&
                    <input 
                      placeholder='¿Cual?'
                      onChange={(e) => setTextCity(e.target.value)}
                      value={textCity}
                    /> 
                  }
                </section>
              }
              { shippingMethod === 'Recoger en tienda' &&
                <section className={CartStyles.selBranch}>
                  { shippingInfo?.collections_points.map( point => (
                      <div className={CartStyles.cardBranch} key={point.id} style={selectedBranch === point.id ? {border: '2px solid #173300', backgroundColor: '#9EE87033'} : {} }>
                        <div>
                          <input type='radio' onChange={() => setSelectedBranch(point.id)} checked={selectedBranch === point.id}/>
                        </div>
                        <div>
                          <p>{point.name}</p>
                          <h3>{point.address}</h3>
                          <h3>{findCityName(point.city_id)}</h3>
                          <p>{point.details_address}</p>
                        </div>
                      </div>
                  ) ) }
                </section>
              }
              {extendedItems.reduce((prev, current) => current.originalPrice + prev, 0) > 0 &&
                <span className={CartStyles.subtotalContainer}>
                  <p>SubTotal</p>
                  <p>
                    {formatNumber(extendedItems.reduce((prev, current) => prev + ((current.productListDiscountPrice || current.price) * current.amount) + current.variantsPrice, 0), store.currency)}
                  </p>
                </span>
              }
              { shippingCity?.shipping_price &&
                <span className={CartStyles.subtotalContainer}>
                  <p>Envío</p>
                  <strong>{ shippingCityFormat }</strong>
                </span>
              }
              { textCity.length >= 3 &&
                <span className={CartStyles.subtotalContainer}>
                  <p>Envío</p>
                  <strong>{ shippingOtherCityFormat
                  
                  
                  }</strong>
                </span>
              }
              { shippingInfo?.shipping_type_id === 2 &&
                <span className={CartStyles.subtotalContainer}>
                  <p>Envío</p>
                  <strong>{shippingPriceFormat}</strong>
                </span>
              }
              { shippingInfo?.shipping_type_id === 1 &&
                <span className={CartStyles.subtotalContainer}>
                  <p>Envío</p>
                  <strong>Envío gratuito</strong>
                </span>
              }
              { shippingCity?.delivery_time &&
                <div className={CartStyles.deliveryTime}>
                  <h3>Entrega estimada</h3>
                  <p>{shippingCity?.delivery_time}</p>
                  <hr className={CartStyles.divider}/>
                </div>
              }
              { shippingInfo?.shipping_type_id !== 3 && shippingInfo?.shipping_time_enabled &&
                <div className={CartStyles.deliveryTime}>
                  <h3>Entrega estimada</h3>
                  <p>{shippingInfo?.shipping_details}</p>
                  <hr className={CartStyles.divider}/>
                </div>
              }
              <span translate='no' className={CartStyles.totalContainer}>
                <h1>
                  Total:
                </h1>
                <h1>
                  {totalForProps}
                </h1>
              </span>
              { extendedItems.length > 0 &&
                <div>
                  {isWhatsApp[0]?.active &&
                    <Button
                      disabled={validateShippingInfo() || extendedItems.length === 0}
                      onClick={() => goToSendOrder()}
                      className={CartStyles.cartShopBtn}
                      variant="contained"
                      startIcon={<ShoppingBag />}
                      fullWidth
                    >
                      {store.callToActionButton || `Ordenar por WhatsApp`}
                    </Button>
                  }
                  {onlinePaymentMethods.length > 0 &&
                    <Button
                    disabled={validateShippingInfo() || extendedItems.length === 0}
                      onClick={goToSendOnlineOrder}
                      className={CartStyles.BtnOnline}
                      variant="contained"
                      startIcon={<CreditCard fontSize="small" />}
                      fullWidth
                    >
                      Pagar en línea
                    </Button>
                  }
                  { shippingInfo?.cash_on_delivery_enabled &&
                    <Button
                      disabled={validateUponDelivery() || extendedItems.length === 0}
                      onClick={() => goToSendOrder('ce')}
                      className={CartStyles.BtnOnline}
                      variant="contained"
                      startIcon={<IconCashDelivery fontSize="small" />}
                      fullWidth
                    >
                      Pagar contraentrega
                    </Button>
                  }
                  <span
                    className={CartStyles.addMoreProductsBtn}
                    onClick={goHome}
                  >
                    <p>Añadir más productos</p>
                  </span>
                </div>
              }
          </aside>
          }
          
        </article>
      </main>
      </div>
  )
}

const mapStateToProps = ({ main }) => ({
  items: main.items,
  store: main.store
});

const mapDispatchToProps = dispatch => bindActionCreators({ setCartState }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cart);