import axios from 'axios';

/**
 * Get Cities for payments
 * @param {string} query query string params
 */
export const getCities = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/cities`)
}

/**
 * Get Payment channels
 * @param {string} query storeId params
 */
export const getPaymentChannels = ( storeId ) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/wa10x/stores/${storeId}/payment-channels-store`)
}

/**
 * Get shipping destination
 * @param {string} query storeId params
 */
export const getShippingDestination = ( storeId ) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/stores/${storeId}/shipping-destination/cities`)
}

/**
 * Get shipping information
 * @param {string} query storeId params
 */
export const getShippingInfo = (storeId) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/stores/${storeId}/shipping`)
}