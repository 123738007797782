// ReactJS
import React, { useEffect } from 'react';

// External dependencies
import { OutlinedInput, FormControl, FormLabel } from '@material-ui/core';

//Pixel FACEBOOK
import ReactPixel from 'react-facebook-pixel'

const ShippingWhatsApp = ({ store, register }) => {
   
    //Facebook pixel
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            store?.facebookPixelCode && ReactPixel.init(store?.facebookPixelCode);
            store?.facebookPixelCode && ReactPixel.track('InitiateCheckout', {
                content_type: 'step delivery',
            })
        }
    }, [store])
    
    return (
        <article>
            <FormControl
                variant="outlined"
                margin="normal"
                fullWidth
            >
            <FormLabel htmlFor='Direccion'>Por favor indica tu dirección</FormLabel>
            <OutlinedInput
                placeholder="Ej. Cll 123 45 67"
                id='Direccion' 
                {...register("Direccion", { required: true })}
                fullWidth 
            />
            </FormControl>
            <FormControl
                variant="outlined"
                margin="normal"
                fullWidth
            >
            <FormLabel htmlFor='Detalles_de_la_direccion'>Detalles de la dirección</FormLabel>
            <OutlinedInput
                placeholder="Ej. Apto, Local, Oficina"
                id='Detalles_de_la_direccion' 
                {...register("Detalles_de_la_direccion")}
                fullWidth 
            />
            </FormControl>
            <FormControl
                variant="outlined"
                margin="normal"
                fullWidth
            >
            <FormLabel htmlFor='Comentarios'>Comentarios</FormLabel>
            <OutlinedInput
                placeholder="Notas o instrucciones de entrega"
                id='Comentarios' 
                {...register("Comentarios")}
                fullWidth 
            />
            </FormControl>
        </article>
    )
}

export default ShippingWhatsApp;