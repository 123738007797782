import React, { useState } from "react"
import { 
  Checkbox,
  FormControl, 
  FormControlLabel, 
  Grid, 
  Input, 
  MenuItem, 
  OutlinedInput, 
  Radio, 
  RadioGroup, 
  Select, 
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core"

// styles
import SendOrderStyles from '../SendOrder/SendOrder.module.scss'

// external dependencies
import classNames from 'classnames/bind'

// internal dependencies
import { countries } from '../../utils/constants'


// assets
import Whatsapp from '../Icons/Whatsapp'
import { Autocomplete } from "@material-ui/lab"

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined' ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode
}

const CustomFormFiels = ({ register, code, setCode, opcQuestions, fieldsForm, formInfo, watch }) => {

  const [focusInputState, setFocusInputState] = useState(false)
  const [symbolsArr] = useState(["e", "E", "+", "-", "."]); /* Symbols not allowed in numeric input  */

  const docTypesOptions = ['Cédula de ciudadanía', 'Tarjeta de identidad', 'Pasaporte', 'NIT']

  const handleCodeChange = newValue => setCode(newValue)
  const toggleFocusInput = state => setFocusInputState(state)

  const whatsAppFieldClass = classNames({
    [SendOrderStyles.numberField]: true,
    [SendOrderStyles.numberFieldFocus]: focusInputState
  })

    return(
      <>
          <FormControl
              variant="outlined"
              margin="normal"
              fullWidth
              >
              <h3 className={SendOrderStyles.formLabel} htmlFor='name'>Ingresa tu nombre y tu apellido</h3>
              <OutlinedInput
                  placeholder="Ingresa tu nombre completo"
                  id='name' 
                  defaultValue={formInfo ? formInfo.name : ''}
                  {...register("name", { required: true })}
                  fullWidth 
                  
              />
          </FormControl>

          <div >
            <FormControl
                variant="outlined"
                margin="normal"
                fullWidth>
                <h3 className={SendOrderStyles.formLabel} focused={focusInputState}>Ingresa tu Whatsapp</h3>
                <div
                className={whatsAppFieldClass}>
                <div className={SendOrderStyles.numberFieldIcon}>
                    <Whatsapp fontSize="small" />
                </div>
                <div className={SendOrderStyles.numberFieldCode}>
                    <CountryAutocomplete
                    id="customerWhatsappCountry"
                    style={{ width: "100%" }}
                    options={countries}
                    size="small"
                    disableClearable
                    disableListWrap
                    noOptionsText="No hay resultados"
                    value={code || ''}
                    onChange={(event, value) => handleCodeChange(value.phone)}
                    getOptionLabel={option => option.phone || code}
                    renderOption={option => (
                        <>
                        <span>{countryToFlag(option.code)}</span>{`${option.label} +${option.phone}`}
                        </>
                    )}
                    renderInput={params => (
                        <TextField
                        {...params}
                        placeholder="(+00)"
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                        }}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable browser autocomplete and autofill
                        }}
                    />
                    )} />
                </div>
                <FormControl className={SendOrderStyles.numberFieldWhatsapp}>
                    <Input
                      required
                      id="customerWhatsapp"
                      placeholder="Ingresa tu número de WhatsApp"
                      type="number"
                      onFocus={() => toggleFocusInput(true)}
                      onBlur={() => toggleFocusInput(false)}
                      defaultValue={formInfo ? formInfo.phone : ''}
                      {...register("phone", { required: true })}
                      disableUnderline
                      fullWidth
                    />
                </FormControl>
                </div>
            </FormControl>
          </div>
          {opcQuestions?.documen_type && 
            <FormControl
                variant="outlined"
                margin="normal"
                fullWidth
              >
                <h3 className={SendOrderStyles.formLabel} id='tipo_de_documento'>Tipo de documento</h3>
                <RadioGroup
                  aria-labelledby='Tipo de documento'
                  name='Tipo de documento'
                >
                {docTypesOptions.map(opc => (
                  <label value={opc}>
                    <FormControlLabel
                      type="radio"
                      name='tipo_de_documento'
                      {...register("tipo_de_documento", { required: true })}
                      control={<Radio color="primary" checked={watch("tipo_de_documento") === opc} />}
                      label={opc}
                      value={opc}
                      id={opc}
                    />
                  </label>
                ))}
              </RadioGroup>
            </FormControl>
          }
          { opcQuestions?.dni &&
            <FormControl
              variant="outlined"
              margin="normal"
              fullWidth
            >
              <h3 className={SendOrderStyles.formLabel} htmlFor='numero_de_documento'>Numero de documento</h3>
              <OutlinedInput
                type='number'
                placeholder="Ingresa tu numero de documento"
                id='numero_de_documento' 
                defaultValue={formInfo ? formInfo.numero_de_documento : ''}
                {...register("numero_de_documento",{ required: true })}
                fullWidth 
                
              />
            </FormControl>
          }
          { opcQuestions?.email &&
            <FormControl
              variant="outlined"
              margin="normal"
              fullWidth
            >
              <h3 className={SendOrderStyles.formLabel} htmlFor='Email'>Email</h3>
              <OutlinedInput
                placeholder="Ingresa tu email"
                id='Email' 
                defaultValue={formInfo ? formInfo.Email : ''}
                {...register("Email", { required: true })}
                fullWidth 
              />
            </FormControl>
          }
          {fieldsForm && fieldsForm.map(field => (
            <>
              {field.type === 'input'
                && <FormControl
                  key={field.id}
                  variant="outlined"
                  margin="normal"
                  fullWidth>
                  <h3 className={SendOrderStyles.formLabel} htmlFor={field.title}>{field.title}</h3>
                  <OutlinedInput
                    id={field.title}
                    placeholder={field.text}
                    defaultValue={formInfo ? formInfo.title : ''}
                    {...register(field.title, { required: field.mandatory ? true : false })}
                    fullWidth 
                    
                  />
                </FormControl>
              }
              {field.type === 'email'
                && <FormControl
                  key={field.id}
                  variant="outlined"
                  margin="normal"
                  fullWidth>
                  <h3 className={SendOrderStyles.formLabel} htmlFor={field.title}>{field.title}</h3>
                  <OutlinedInput
                    id={field.title}
                    placeholder={field.text}
                    type="email"
                    defaultValue={formInfo ? formInfo.title : ''}
                    {...register(field.title, { required: field.mandatory ? true : false })}
                    fullWidth 
                      
                  />
                </FormControl>
              }
              {field.type === 'number'
                && <FormControl
                  key={field.id}
                  variant="outlined"
                  margin="normal"
                  fullWidth>
                  <h3 className={SendOrderStyles.formLabel} htmlFor={field.title}>{field.title}</h3>
                  <OutlinedInput
                    id={field.title}
                    placeholder={field.text}
                    onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                    type="number"
                    defaultValue={formInfo ? formInfo.title : ''}
                    {...register(field.title, { required: field.mandatory ? true : false })}
                    fullWidth 
                    
                  />
                </FormControl>
              }
              {field.type === 'textarea' && 
                <FormControl
                  key={field.id}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                >
                  <h3 className={SendOrderStyles.formLabel} htmlFor={field.title}>{field.title}</h3>
                  <OutlinedInput
                    id={field.title}
                    placeholder={field.text}
                    minRows={4}
                    multiline={true}
                    defaultValue={formInfo ? formInfo.title : ''}
                    {...register(field.title, { required: field.mandatory ? true : false })}
                    fullWidth 
                    
                  />
                </FormControl>
              }
              {field.type === 'select' && 
                <FormControl
                  key={field.id}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                >
                  <h3 className={SendOrderStyles.formLabel} htmlFor={field.title}>{field.title}</h3>
                  <Select
                    labelId={field.title}
                    id={field.title}
                    label={field.title}
                    defaultValue={formInfo ? formInfo[field.title] : ''}
                    {...register(field.title, { required: field.mandatory ? true : false })}
                    className={SendOrderStyles.selectField}
                  >
                    <MenuItem value='' disabled>Selecciona una opción...</MenuItem>
                    {field.options.map(opc => (
                      <MenuItem key={opc} value={opc}>{opc}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
              {field.type === 'radio' && 
                <FormControl
                  key={field.id}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                >
                  <h3 className={SendOrderStyles.formLabel} id={field.title}>{field.title}</h3>
                  <RadioGroup
                    aria-labelledby={field.title}
                    name={field.title}
                    defaultValue={formInfo ? formInfo[field.title] : ''}
                  >
                    {field.options.map(opc => (
                      <label value={opc}>
                        <FormControlLabel
                          type="radio"
                          name={field.title}
                          {...register(field.title, { required: field.mandatory ? true : false })}
                          control={<Radio color="primary" />}
                          label={opc}
                          value={opc}
                          id={opc}
                        />
                      </label>
                    ))}
                  </RadioGroup>
                </FormControl>
              }
              {field.type === 'checkbox' &&
                <FormControl
                  key={field.id}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  
                >
                  <h3 className={SendOrderStyles.formLabel} >{field.title}</h3>
                  {field.options.map(opc => (
                    <FormControlLabel 
                      label={opc} 
                      defaultValue={formInfo ? formInfo[field.title] : ''}
                      {...register(field.title, { required: field.mandatory ? true : false })} 
                      control={
                        <Checkbox value={opc} color="primary" />
                      } 
                    />
                  ))}
                </FormControl>
              }
              {field.type === 'label'
                && <section>
                  <h3>{field.title}</h3>
                  <p>{field.text}</p>
                </section>
              }
              {field.type === 'date' && 
              <FormControl
                key={field.id}
                variant="outlined"
                margin="normal"
                fullWidth
              >
                  <h3 className={SendOrderStyles.formLabel} htmlFor={field.title}>{field.title}</h3>
                  <OutlinedInput
                    id={field.title}
                    className={SendOrderStyles.dateField}
                    placeholder="dd/mm/aaaa"
                    type="date"
                    {...register(field.title, { required: field.mandatory ? true : false })}
                    fullWidth 
                    
                  />
                </FormControl>
              }
              {field.type === 'time'
                && <FormControl
                  key={field.id}
                  variant="outlined"
                  margin="normal"
                  fullWidth>
                  <h3 className={SendOrderStyles.formLabel} htmlFor={field.title}>{field.title}</h3>
                  <OutlinedInput
                    id={field.title}
                    placeholder="--:--"
                    className={SendOrderStyles.dateField}
                    type="time"
                    {...register(field.title, { required: field.mandatory ? true : false })}
                    fullWidth 
                      
                  />
                </FormControl>
              }
              {field.type === 'range'
                && <FormControl
                  key={field.id}
                  variant="outlined"
                  margin="normal"
                  fullWidth>
                  <h3 className={SendOrderStyles.formLabel} htmlFor={field.title}>{field.title}</h3>
                  <br />

                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Typography
                        variant="body1"
                        style={{ color: '#53627C', fontSize: 16 }}
                      >
                        Desde:
                      </Typography>
                      <OutlinedInput
                        id={field.title}
                        className={SendOrderStyles.dateField}
                        placeholder="dd/mm/aaaa"
                        label="Desde:"
                        type="date"
                        {...register(`${field.title}.desde`, { required: field.mandatory ? true : false })}
                        fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body1"
                        style={{ color: '#53627C', fontSize: 16 }}
                      >
                        Hasta:
                      </Typography>
                      <OutlinedInput
                        id={field.title}
                        className={SendOrderStyles.dateField}
                        placeholder="dd/mm/aaaa"
                        label="Hasta:"
                        type="date"
                        {...register(`${field.title}.hasta`, { required: field.mandatory ? true : false })}
                        fullWidth />
                    </Grid>
                  </Grid>
                </FormControl>
              }
            </>
          )  
        ) }
      </>
    )
}

function CountryAutocomplete(props) {
  const classes = countryAutoCompleteStyles()
  return (
    <Autocomplete classes={classes} {...props} />
  )
}

const countryAutoCompleteStyles = makeStyles(theme => ({
  popper: {
    width: '300px !important',
    left: '16px !important'
  },
  option: {
    fontSize: 13,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  input: {
    padding: '6px 0 !important',
  }
}))

export default CustomFormFiels