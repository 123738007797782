/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */

// React dependencies
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import debounce from "just-debounce-it"

// External dependencies
import { useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { hotjar } from 'react-hotjar';



// Material UI dependencies
import { Container, Grid, Typography, Link, Box, CircularProgress, Button } from '@material-ui/core';

// Internal components
import Category from '../Category';
import CategoryGroup from '../CategoryGroup';
import Gallery from '../Gallery';
import GalleryItem from '../GalleryItem';
import Header from '../Header';
import WhatsAppWidget from '../../components/WhatsAppWidget/WhatsAppWidget.js';
import Loader from '../Loader';

// Internal dependencies
// import { setStoreState,  } from '../../modules/main';
import { setStoreState /*setHomeScrollPosition*/ } from '../../modules/main';
import { STORE_BY_URL_QUERY } from '../../qgl-queries/store';
import { findValidUrl, getStoreHeader } from '../../utils/constants';

//Pixel FACEBOOK
import ReactPixel from 'react-facebook-pixel';

//Google Analitycs
import ReactGA from "react-ga4";

// styles
import StoreStyles from './Store.module.scss'
import NotFound from '../NotFound';
import { Refresh } from 'mdi-material-ui';
import { ToTopIcon } from '../../assets/icons/to-top.js';

/**
 * Renders the Store page
 * @param {Array} items cart items from store
 * @param {Object} store store object from store
 */
const Store = ({ items, store, setStoreState /*homeScrollPosition, setHomeScrollPosition*/ }) => {
  // const visorRef = useRef()
  const { phoneNumber, nameCollection } = useParams()
  const { enqueueSnackbar } = useSnackbar();
  const storeHeader = getStoreHeader()
  const history = useHistory();
  const storeLocation = window.location.hostname.split(".")[0]
  const storeUrl = storeHeader ? storeHeader : storeLocation
  
  // State variables
  const [showingCollection, setShowingCollection] = useState(false)
  const [currentCategory, setCurrentCategory] = useState('')
  const [storeData, setStoreData] = useState({
    userMobileNumber: '',
    storeName: '',
    profilePicture: '',
    storeDescription: '',
    bannerPicture: '',
    url: '',
    minimumCartValue: null,
    verified: false,
    storeCollections: [],
  });
  const [cartItems, setCartItems] = useState(0);
  const [stateData, setData] = useState([])
  const [limits, setLimit] = useState(0);
  const [id, setID] = useState('');
  const [isLoading, setLoading] = useState(false)
  const [isEmpty, setEmpty] = useState(false);
  const [wa, setWa] = useState(true)
  const [publicCategories, setPublicCategories] = useState([])
  const [totalProds, setTotalProds] = useState(null)

  // Store query
  const { loading, error, data } = useQuery(STORE_BY_URL_QUERY, {
    variables: { url: storeUrl }
  });

  const titleCategory = data?.store?.storeCatalogs[0]?.collections.find(col => col?.id === id)?.name.toLowerCase()
  const debounceLimit = useCallback(debounce(() => setLimit(prev => prev + 30), 500), [])
  const logoUrl = 'https://get10x.co/?utm_source=tiendareferido&utm_medium=referral&utm_campaign=tiendareferido&utm_content=tiendareferido'

  const getStoreWithInfinityScroll = (currentCategory) => {
  setLoading(true)
  axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/store/${storeUrl}/products`,
      params: {
        collection: currentCategory,
        limit: 30,
        offset: limits,
      }
    })
    .then(res => {
        setID(res.data.id);
        currentCategory === '' ? setData([]) : setData([...stateData, ...res.data.products])
        currentCategory !== '' && setTotalProds(res.data.total_products)
        setLoading(false)
        debounceLimit()
      })
      .catch(err => {
        console.log(err)
        setEmpty(true)
        setLoading(false)
      }
    )
  }
  // const lastProduct = useCallback(node => {
  //   if (!node) return
  //   if (visorRef.current) visorRef.current.disconnect()
    
  //   const options = {
  //     root: null,
  //     rootMargin: '-100px 0px 0px 0px', 
  //     threshold: 0.1, 
  //   }
    
  //   visorRef.current = new IntersectionObserver(entries => {
  //     if (entries[0].isIntersecting) {
  //       debounceLimit()
  //       getStoreWithInfinityScroll(currentCategory)
  //     }
  //   }, options)
  //   visorRef.current.observe(node)
  // }, [limits, currentCategory])

  // Create empty array to populate it with products
  let allProducts = [];

  // Filter all products by collection
  if (stateData?.length !== 0) {
    id && allProducts.push(...stateData)
  }

  // Remove duplicated products
  const filteredProducts = uniqBy(allProducts, 'id').filter(product => product?.quantityAvailable !== 0 && product?.active);

  // Filter all products by date
  const orderedProducts = orderBy(filteredProducts, ['created_at'], ['asc'])
console.log(orderedProducts.length, limits)
  const updateCurrentCategory =  id => {
     const nameCategory = data?.store?.storeCatalogs[0]?.collections.find(col => col?.id === id)?.slug
     
     let phone 
      const currentPhone = () => {
        if (phoneNumber === 'undefined' || phoneNumber === '404') {
          phone = data?.store?.userStores[0]?.mobilePhoneNumber
        }else{
          phone = phoneNumber
        }
      }
      currentPhone()
      setData([]);
      setCurrentCategory(id);
      setShowingCollection(true);
      setLimit(0)
      setTimeout(() => setShowingCollection(false), 250)

      history.push(`/${phone}/collections/${nameCategory}`)
  }
  
  const showLoader = store.id ? false : loading;
  //const saveScrollPosition = () => setHomeScrollPosition(window.scrollY);

  
  /* EFFECTS HERE ⬇️ */
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      hotjar.initialize(1702922, 6);
    
      /* Google Analytics */
      if ( store?.googleAnalitycsTrackingId ) {
        ReactGA.initialize([
          {
            trackingId: store?.googleAnalitycsTrackingId
          },
          {
            trackingId: 'UA-163783687-1'
          }
      ]);
      }
      else{
        ReactGA.initialize([
          {
            trackingId: 'UA-163783687-1'
          }
        ])
      }
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
      // store?.googleAnalitycsTrackingId && ReactGA.initialize(store?.googleAnalitycsTrackingId);
      // store?.googleAnalitycsTrackingId && ReactGA.send(window.location.pathname + window.location.search);

      /* Facebook Pixel */
      store?.facebookPixelCode && ReactPixel.init(store?.facebookPixelCode);
      store?.facebookPixelCode && ReactPixel.pageView();
    }
    const cartItems = items.map(item => ({ ...item }))
    setCartItems(cartItems.reduce((prev, current) => prev + current.amount, 0))
  }, []);
  

  // useEffect(() => {
  //   window.document.addEventListener('scroll', saveScrollPosition);

  //   setTimeout(() => {
  //     window.scroll({
  //       top: homeScrollPosition,
  //       behavior: 'auto',
  //     });
  //   });

  //   return () => window.document.removeEventListener('scroll', saveScrollPosition);
  // }, [])

  useEffect(() => {
    if (error) {
      enqueueSnackbar('No pudimos recuperar los datos de la tienda, inténtalo mas tarde', {
        variant: 'error',
        autoHideDuration: 6000
      });
    }
    if ((storeData && (storeData.storePlanStatus && storeData.storePlanStatus.name === 'Inhabilitado') || (storeData.storePlanStatus && storeData.storePlanStatus.name === 'Prueba Fallida'))) {
      enqueueSnackbar('Esta tienda 10X requiere atención.', {
        variant: 'error',
        persist: true,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      });
      setWa(false)
    }
  }, [error, store]);

  useEffect(() => {
    if (data) {
      if (data.store === null || data.store.id === "") {
        console.log('Shop not found')
      } else {
        document.title = data.store.name;

        setStoreData({
          ...data.store,
          storeCollections: data.store.storeCatalogs[0].collections.filter(collection => collection.public),
        });
        setStoreState(storeUrl, phoneNumber, items, data.store);
      }
    }
    if (store.id) {
      const getValidUrl = () => {
        const defaultUserStore = store.userStores.find(us => us.default);
        const firstUserStore = store.userStores[0]

        return defaultUserStore || firstUserStore
      }
      const setHistory = () => history.push(`/${getValidUrl().mobilePhoneNumber}`)
      if(phoneNumber === undefined) {
        setHistory()
      }
      findValidUrl(store, phoneNumber, setHistory)
    }
  }, [data, store]);
  
  useEffect(() => {
    let category = [];
    if (data) {
      const pub = data?.store?.storeCatalogs[0]?.collections?.filter(collection => collection.public).length > 0 ? data?.store?.storeCatalogs[0]?.collections?.filter(collection => collection.public) : []
      setPublicCategories(pub ? pub : [] )
      if (nameCollection) {
        if (pub.length > 0) {
          const validate = pub.filter(collection => collection?.slug?.toUpperCase() === nameCollection?.toUpperCase())
          
          
          if (validate.length === 0){
            category = [pub[0]]
          }else{
            category = [validate[0]]
          }
        }else{
          category = ''
        }
      }else {
        category = pub && [pub[0]] 
        if (category.length === 0 && nameCollection !== 'undefined') {
          category = data?.store?.storeCatalogs[0]?.collections
          .filter(collection => collection?.slug?.toUpperCase() === "TODO")
        }
      }
    }
    category?.length > 0 &&  updateCurrentCategory(category[0]?.id)
    
  }, [data, currentCategory])
  
  useEffect(() => {
    setData([]);
    setLimit(0);
    getStoreWithInfinityScroll(currentCategory)
    isEmpty && setEmpty(false)
  }, [currentCategory])
  
  /* EFFECTS HERE ⬆️ */

  return (
    data?.store === null || data?.store?.id === "" || error
      ? <NotFound storeName={storeUrl} />
      :
      loading
        ?
        <Loader />
        :
        <main className={StoreStyles.container}> 
          <Header
            loading={showLoader}
            storeId={storeData.id}
            storeNumber={storeData.userMobileNumber}
            shopTitle={storeData.name}
            shopLogo={storeData.profilePicture}
            shopDescription={storeData.description}
            shopCover={storeData.bannerPicture}
            verified={storeData.verified}
            cartUrl={`/${phoneNumber}/order/products`}
            productSubUrl={`/${phoneNumber}`}
            cartItems={cartItems === 0}
            shopType={storeData.minimumCartValue}
          />
          { publicCategories.length > 1 &&
              <CategoryGroup
                isInactive={(storeData && (storeData.storePlanStatus && storeData.storePlanStatus.name === 'Inhabilitado') || (storeData.storePlanStatus && storeData.storePlanStatus.name === 'Prueba Fallida')) ? true : false}
                categoriesQty={storeData.storeCollections.length}
                title={titleCategory}
              >
                {(showLoader) &&
                  <Category
                    loading={showLoader}
                    key={'all'}
                    id={null}
                    current={currentCategory}
                    setActive={updateCurrentCategory}
                    image={storeData.profilePicture}
                    caption={'Todo'}
                  />
                }
                {storeData.storeCollections.length !== 0 ?
                  storeData.storeCollections.map((collection, index) =>
                    <Category
                      index={index}
                      loading={showLoader}
                      key={collection.id}
                      id={collection.id}
                      current={currentCategory}
                      setActive={updateCurrentCategory}
                      image={collection.pictureUrl}
                      caption={collection.name}
                    />
                  ) : null}
              </CategoryGroup>
          }
          <Gallery
            isInactive={(storeData && (storeData.storePlanStatus && storeData.storePlanStatus.name === 'Inhabilitado') || (storeData.storePlanStatus && storeData.storePlanStatus.name === 'Prueba Fallida')) ? true : false}
            fadeCollection={showingCollection}
          >
            {showLoader && [1, 2, 3, 4].map(product =>
              <GalleryItem
                loading={showLoader}
                key={product}
                url=""
                alt=""
                thumbnail=""
                productName=""
                productPrice=""
                currency={storeData.currency}
              />
            )}
            {!showLoader && orderedProducts.map((product, index) =>
                <GalleryItem
                  currency={storeData.currency}
                  loading={showLoader}
                  key={product.id}
                  url={`/${phoneNumber}/${product.slug}?ref=${nameCollection}`}
                  alt={product.name}
                  thumbnail={
                    product?.product_images?.length > 0 ? (
                      product.product_images[0].picture_url
                    ) : ``
                  }
                  productName={product.name}
                  productPrice={product.price}
                  productDiscountPrice={product.discount_price}
                />
            )}
          </Gallery>
          { isLoading
            ?
            <Box
            style={{ height: '100%', marginBottom: '40px' }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            >
              <CircularProgress />
            </Box>
            : null
          }
            { (!isEmpty && !isLoading && totalProds > orderedProducts.length)  && 
               <p 
               className={StoreStyles.load}
               onClick={() => getStoreWithInfinityScroll(currentCategory)}
             > 
               Cargar más productos
             </p>
            }
          {storeData.branding &&
            <Grid container className={StoreStyles.get10X} >
              <Link href={logoUrl} target="_blank"> 
                <img src="/logo.png" alt="10X" style={{width: 55, margin: '15px 0'}} />
              </Link>
              <p>
                Crea tu propia tienda virtual con Ecometri
              </p>
              <Link href={logoUrl} target="_blank"> 
                Regístrate
              </Link>
            </Grid>
          }
          { window.scrollY > 450 &&
            <div className={StoreStyles.main} onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
              <ToTopIcon />
            </div>
          }
          { wa &&
            <WhatsAppWidget
              phoneNumber={phoneNumber}
              textReplyTime={false}
              message='¡Hola! 👋🏼  ¿En qué te puedo ayudar?'
              companyName={storeData.name}
              placeholder="Escribe un mensaje"
              sendButton='Enviar'
              shopLogo={storeData.profilePicture}
            />
          }
          
          {/* <BrandingCard /> */}
        </main>
  )
}

const mapStateToProps = ({ main }) => ({
  items: main.items,
  store: main.store,
  //homeScrollPosition: main.homeScrollPosition
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setStoreState,
  //setHomeScrollPosition
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Store);