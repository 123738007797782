import React from 'react'
import ZoomStyles from './ZoomImage.module.scss'
import { IconClose } from '../../assets/icons/close'
import ChevronRight from '../Icons/ChevronRight'
import ChevronLeft from '../Icons/ChevronLeft'

const ZoomImage = ({ isOpen, onClose, image, slideImage, imageIndex, imageLength }) => {
  const image_src = JSON.stringify({
    "bucket": "wa10x.v2",
    "key": `${image}`,
    "edits": {
      "resize": {
        "width": 600,
        //"height": 600,
        "fit": "cover"
      },
      "rotate": null,
    }
  })

  const productImage = image ? `${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(image_src).toString('base64')}` : `/10x-product.jpg`;
  
  if (!isOpen) return null

  return (
    <div className={ZoomStyles.dialogBackdrop} onClick={onClose}>
      <div className={ZoomStyles.dialogContent} onClick={e => e.stopPropagation()}>
          <span onClick={onClose}>
              <IconClose/>
          </span>
          <div className={ZoomStyles.btnsContainer}>
            { imageIndex > 0 
              ?<div onClick={() => slideImage('prev')}>
                <ChevronLeft/>
              </div>
              :<p></p>
            }
            { imageIndex < imageLength - 1
              ?<div onClick={() => slideImage('next')}>
                <ChevronRight/>
              </div>
              :<p></p>
            }  
          </div>
        <img src={productImage} alt="Enlarged view" />
      </div>
    </div>
  )
}

  export default ZoomImage