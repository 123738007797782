import React, { useContext } from 'react'

import WidgetContext from '../WidgetContext/WidgetContext'

import styles from './WhatsAppButton.module.css'
import { IconWA } from '../../../assets/icons/whatsapp'

export default function WhatsAppButton() {
  const { handleOpen } = useContext(WidgetContext)

  return (
    <div className={styles.root} onClick={handleOpen}>
      <IconWA />
    </div> 
  )
}
