// react dependencies
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

// external dependencies
import classNames from 'classnames/bind'
import { useSnackbar } from 'notistack'
import axios from 'axios'

//Pixel FACEBOOK
import ReactPixel from 'react-facebook-pixel'

// material dependencies
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Link,
  makeStyles,
} from '@material-ui/core'


// internal dependencies
import TermsAndConditions from '../TermsAndConditions'
import PrivacyPolicy from '../PrivacyPolicy'
import StoreTermsAndConditions from '../StoreTermsAndConditions'
import { STORE_ONLINE_PAYMENTS_BY_URL_QUERY } from '../../qgl-queries/storeOnlinePayments'
import { STORE_TOS_BY_URL_QUERY } from '../../qgl-queries/storeToS'
import { capitalizeWord, findValidUrl, formatNumber } from '../../utils/constants'

// assets
import ChevronLeft from '../Icons/ChevronLeft';
import Whatsapp from '../Icons/Whatsapp';
import exmpleimg from '../../assets/images/emptyCart.png'

//functions
import { cleanShoppingCart } from '../../utils/constants'

// styles
import SendOrderStyles from './SendOrder.module.scss'
import { useForm } from 'react-hook-form'
import ShippingWhatsApp from './ShippingForm/ShippingForm'
import CustomFormFiels from '../FormFields/FormFields'

const SendOrder = ({ items, store }) => {
  const { /* storeUrl, */ phoneNumber } = useParams()
  const history = useHistory()
  const storeUrl = window.location.hostname.split(".")[0]

  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState(null)
  const [fieldsForm, setFieldsForm] = useState(null)
  const [code, setCode] = useState('57')
  const [openTermsDialog, setOpenTermsDialog] = useState(false)
  const [openPrivacyDialog, setOpenPrivacyDialog] = useState(false)
  const [openStoreTermsDialog, setOpenStoreTermsDialog] = useState(false)
  const [sent, setSent] = useState(false)
  const [allItems, setAllItems] = useState([])
  const [storeToS, setStoreToS] = useState('')
  const [formInfo, setFormInfo] = useState(null)
  const [currentStep, setCurrentStep] = useState(1)
  const [opcQuestions, setOpcQuestions] = useState({})
  const [deliveryInfo, setDeliveryInfo] = useState(null)
  const [total] = useState(localStorage.getItem('totalForProps'))
  
  const userStore = store?.userStores?.find(uStore => uStore.mobilePhoneNumber === phoneNumber)
  
  const existShippingInfo = opcQuestions?.aditional_shipping_details && deliveryInfo?.shipping_method === 'Domicilio' ? true : false
   

  // Store query
  const { data } = useQuery(STORE_ONLINE_PAYMENTS_BY_URL_QUERY, {
    variables: { url: storeUrl }
  });

  // Store terms and conditions query
  const { data: storeTosData } = useQuery(STORE_TOS_BY_URL_QUERY, {
    variables: { url: storeUrl }
  });

  const { register, handleSubmit, watch, setValue, getValues, formState: { isValid } } = useForm({ 
    mode: 'onChange',
  });
  const onSubmit = data => {
    const infoForm = Object.entries(data)
    localStorage.setItem('StepData', JSON.stringify({
      name: infoForm[0][1],
      phone: infoForm[1][1],
    }))
    if(existShippingInfo && currentStep === 2){
      putOrder(data, infoForm)
    }else{
      saveOrder(data, infoForm)
    }
  }

  const handleNextStep = () => {
    const currentFormData = getValues()
    localStorage.setItem('formData', JSON.stringify(currentFormData))
    setFormInfo(currentFormData)
    
    const orderPostKey =  localStorage.getItem('saved')
    if(orderPostKey){
      setCurrentStep(2)
    }else{
      axios.post(`${process.env.REACT_APP_API_URL}/wa10x/${store.id}/${phoneNumber}/order`, {
        order_details: allItems.map(item => ({
          product_id: item.id,
          price: item.productListDiscountPrice || item.price,
          quantity: item.amount,
          options: item.productOptions.map(option => +option.selected),
          ...item.variantId && { product_variant_id: +item.variantId }
        })),
        customer: {
          first_name: currentFormData.name,
          phone_number: `${code}${currentFormData.phone}`,
        },
        user_store_id: +userStore.id,
        direct_sale: userStore.directSaleEnabled,
        order_method_id: 1
      })
      .then( res => {
        localStorage.setItem('saved', res.data.id)
        setCurrentStep(2)
      })
    }
  }
  const handlePreviousStep = () => {
    const savedFormData = JSON.parse(localStorage.getItem('formData'))
    if (savedFormData) {
      Object.keys(savedFormData).forEach(key => {
        setValue(key, savedFormData[key])
      })
    }
    setCurrentStep(1)  
    setValue('termsCheck', false)   
  }
  
  // functions
  const goBack = () => history.push(`/${phoneNumber}/order/products`);
  const handleClosePrivacyDialog = () => setOpenPrivacyDialog(false);
  const handleCloseTermsDialog = () => setOpenTermsDialog(false);
  const handleCloseStoreTermsDialog = () => setOpenStoreTermsDialog(false);

  const handleOpenTermsDialog = (e) => {
    e.preventDefault();
    setOpenTermsDialog(true);
  }
  const handleOpenPrivacyDialog = (e) => {
    e.preventDefault();
    setOpenPrivacyDialog(true);
  }
  const handleOpenStoreTermsDialog = (e) => {
    e.preventDefault();
    setOpenStoreTermsDialog(true);
  }

  const normalizeDateRange = order => {
    const range = order?.filter(dato => typeof dato[1] === 'object' && dato[1]?.desde && dato[1] !== null)
    return range.map(r => `*${r[0]}:* ${Object.entries(r[1])?.map(i => `_${capitalizeWord(i[0])}_ - ${capitalizeWord(i[1])}`).join(', ')}\n`).join(' ')
  }

  const productsWa = allItems.map(item => {
    let product = '';
    if (item.productOptions.length) {
      product = item.productOptions.reduce((acc, current) => {
        if (current.productOptionValues.length > 0) {
          const currentProduct = current.productOptionValues.find((pov, i) => pov.id === current.selected);
          return acc + `${currentProduct.name}, `;
        }
        return ''
      }, '');
    }
    return `- ${item.amount} *${item.name} ${item.sku ? item.sku : ''}* ${product ? '- ' + product : ''}/  ${item.price > 0 ? `_${formatNumber((item.productListDiscountPrice || item.price), store.currency)}_` : ''} \n`
  })

  const saveInfoShipping = (orderResId ,dataForm) => {
    const shippData = {
      comments: dataForm.Comentarios,
      shipping_information: {
        address: dataForm.Direccion,
        address_detail: dataForm.Detalles_de_la_direccion,
        city: deliveryInfo.delivery_city,
        country_code: 'CO',
        city_id: deliveryInfo.sel_city_id,
        store_pickup_enabled: deliveryInfo.shipping_method === 'Recoger en tienda' ? true : false,
        cash_on_delivery_enabled: deliveryInfo.cash_on_delivery ,
        collection_point_id: deliveryInfo.branch.id,
        other_zone: !deliveryInfo.delivery_city ? true : false
      }
    }
    axios.put(`${process.env.REACT_APP_API_URL}/wa10x/stores/${store.id}/orders/${orderResId}/shipping-info`, shippData)
    .then(res => console.log(res))
    .catch( err => console.log(err))
  }

  const saveInfoAd = (dataForm, orderResId) => {
    const adiData = {
      customer: {
        dni: dataForm.numero_de_documento ? dataForm.numero_de_documento : '',
        email: dataForm.Email ? dataForm.Email : '',
        document_type: dataForm.tipo_de_documento ? dataForm.tipo_de_documento : '',
        phone_number: `${code}${dataForm.phone}`
      }
    }
    axios.put(`${process.env.REACT_APP_API_URL}/wa10x/stores/${store.id}/orders/${orderResId}/customer-info`, adiData)


    .then(res => console.log(res))
    .catch( err => console.log(err))
  }

  const putOrder = (dataForm, infoForm) => {
    const orderPutId = localStorage.getItem('saved')

    setSent(true);
    const result = infoForm && infoForm.filter(info => (info[0] !== 'name' && info[0] !== 'phone' && !info[1]?.desde && info[0] !== 'termsCheck'));
    const objectEntries = normalizeDateRange(infoForm)

    const messageUrl = `https://wa.me/${userStore.directSaleEnabled ? `${code}${dataForm.phone}` : phoneNumber}?text=`;
    
    const ShippingInfoLS = `
Información de envío:
${ deliveryInfo?.shipping_method ? `*Tipo de envio*: ${deliveryInfo?.shipping_method}` : ''}
${ deliveryInfo?.delivery_city  ? `*Ciudad de envío*: ${deliveryInfo?.delivery_city}` : ''}
${ deliveryInfo?.branch?.name ? `*Nombre de la sucursal física*: ${deliveryInfo?.branch?.name}` : ''}
${ deliveryInfo?.branch?.address ? `*Dirección de la sucursal física*: ${deliveryInfo?.branch?.address}` : ''}
${ deliveryInfo?.cash_on_delivery ? `*Pagar contraentrega*: ${deliveryInfo?.shipping_method === 'true' ? 'SI' : 'NO'}` : ''}
`
        const message = `
Hola, soy ${dataForm.name},
quiero hacer este pedido en ${store.name}:
========================\n
${productsWa.map(product => product.trim()).join('\n')}
========================\n
TOTAL: *${total}*
========================\n
${store.deliveryCostEnabled ? `*Envío:* ${store.deliveryCostDetails || ``}` : ``}
${store.shippingTimeEnabled ? `*Tiempo de entrega:* ${store.shippingDetails || ``}` : ``}
========================\n
Mi información:\n
*Nombre:* ${dataForm.name}\n *Celular:* +${code}${dataForm.phone}\n ${result && result.map(dato => `*${dato[0]}:* ${dato[1]}\n`).join(' ')} ${objectEntries && objectEntries}
========================\n
Mi pedido: ${window.location.origin}/order-review/${store.id}/${orderPutId}
========================\n
${ ShippingInfoLS }
        `
  const arrToString = arr => arr.join(', ')
  const objTostring = obj => JSON.stringify(obj)
  const mappedResponses = fieldsForm.map( resField => ({
    form_id: resField.form_id,
    form_field_id: resField.id,
    type: resField.type,
    title: resField.title,
    response: resField.type === 'checkbox' && arrToString(dataForm[resField.title]) || resField.type === 'range' && objTostring(dataForm[resField.title]) || dataForm[resField.title],
    order_id: orderPutId
  }) )
  
  axios.post(`${process.env.REACT_APP_API_URL}/stores/${store.id}/orders/${orderPutId}/dynamic-form-responses`, mappedResponses)
  .then((response) => {
    setSent(false)
    saveInfoShipping(orderPutId, dataForm)
    saveInfoAd(dataForm, orderPutId)
    window.location.href = `${messageUrl}${encodeURIComponent(message).replace(/%20/g, '+')}`;
    cleanShoppingCart()
    localStorage.removeItem('saved')
    ReactPixel.track('Purchase', {
      content_type: 'checkout',
      num_items: items.length,
    })
  })
  .catch( err => console.log(err))
  }

  const saveOrder = (dataForm, infoForm) => { 
    setSent(true);
    const result = infoForm && infoForm.filter(info => (info[0] !== 'name' && info[0] !== 'phone' && !info[1]?.desde && info[0] !== 'termsCheck'));
    const objectEntries = normalizeDateRange(infoForm)

    const messageUrl = `https://wa.me/${userStore.directSaleEnabled ? `${code}${dataForm.phone}` : phoneNumber}?text=`
    
    axios.post(`${process.env.REACT_APP_API_URL}/wa10x/${store.id}/${phoneNumber}/order`, {
      order_details: allItems.map(item => ({
        product_id: item.id,
        price: item.productListDiscountPrice || item.price,
        quantity: item.amount,
        options: item.productOptions.map(option => +option.selected),
        ...item.variantId && { product_variant_id: +item.variantId }
      })),
      customer: {
        first_name: dataForm.name,
        phone_number: `${code}${dataForm.phone}`,
      },
      user_store_id: +userStore.id,
      direct_sale: userStore.directSaleEnabled,
      order_method_id: 1
    })
      .then((orderRes) => {
        const ShippingInfoLS = `
Información de envío:
${ deliveryInfo?.shipping_method ? `*Tipo de envio*: ${deliveryInfo?.shipping_method}` : ''}
${ deliveryInfo?.delivery_city  ? `*Ciudad de envío*: ${deliveryInfo?.delivery_city}` : ''}
${ deliveryInfo?.branch?.name ? `*Nombre de la sucursal física*: ${deliveryInfo?.branch?.name}` : ''}
${ deliveryInfo?.branch?.address ? `*Dirección de la sucursal física*: ${deliveryInfo?.branch?.address}` : ''}
${ deliveryInfo?.cash_on_delivery ? `*Pagar contraentrega*: ${deliveryInfo?.shipping_method === 'true' ? 'SI' : 'NO'}` : ''}
`
        const message = `
Hola, soy ${dataForm.name},
quiero hacer este pedido en ${store.name}:
========================\n
${productsWa.map(product => product.trim()).join('\n')}
========================\n
TOTAL: *${total}*
========================\n
${store.deliveryCostEnabled ? `*Envío:* ${store.deliveryCostDetails || ``}` : ``}
${store.shippingTimeEnabled ? `*Tiempo de entrega:* ${store.shippingDetails || ``}` : ``}
========================\n
Mi información:\n
*Nombre:* ${dataForm.name}\n *Celular:* +${code}${dataForm.phone}\n ${result && result.map(dato => `*${dato[0]}:* ${dato[1]}\n`).join(' ')} ${objectEntries && objectEntries}
========================\n
Mi pedido: ${window.location.origin}/order-review/${store.id}/${orderRes.data.id}
========================\n
${ ShippingInfoLS }
        `
    saveInfoShipping(orderRes.data.id, dataForm)   
    if(opcQuestions?.dni || opcQuestions?.email || opcQuestions?.documen_type) {
      saveInfoAd(dataForm, orderRes.data.id)
    }
        if(!formData){
          setSent(false)
          window.location.href = `${messageUrl}${encodeURIComponent(message).replace(/%20/g, '+')}`
          cleanShoppingCart()
        }else{
          const arrToString = arr => arr.join(', ')
          const objTostring = obj => JSON.stringify(obj)
          const mappedResponses = fieldsForm.map( resField => ({
            form_id: resField.form_id,
            form_field_id: resField.id,
            type: resField.type,
            title: resField.title,
            response: resField.type === 'checkbox' && arrToString(dataForm[resField.title]) || resField.type === 'range' && objTostring(dataForm[resField.title]) || dataForm[resField.title],
            order_id: orderRes.data.id
          }) )
          
          axios.post(`${process.env.REACT_APP_API_URL}/stores/${store.id}/orders/${orderRes.data.id}/dynamic-form-responses`, mappedResponses)
          .then((response) => {
            setSent(false)
            
            window.location.href = `${messageUrl}${encodeURIComponent(message).replace(/%20/g, '+')}`;
            cleanShoppingCart()
            ReactPixel.track('Purchase', {
              content_type: 'checkout',
              num_items: items.length,            })
          })
          .catch( err => console.log(err))
        }
      })
      .catch((err) => {
        setSent(false);
        enqueueSnackbar('No pudimos crear el pedido, intentalo de nuevo', {
          variant: 'error',
          autoHideDuration: 2500
        })
      })
  }

  const numberWithDots = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  /* EFFECTS HERE ⬇️ */
  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/stores/${store.id}/dynamic-form`)
    .then(res => {
      const { data } = res
      const { fields } = data

      setFormData(data)
      setFieldsForm(fields)
      setOpcQuestions({
        aditional_shipping_details: data.aditional_shipping_details,
        dni: data.dni,
        documen_type: data.documen_type,
        email: data.email
      })
      setLoading(false)
    })
    .catch(err => setLoading(false))

  }, [store.id])

  useEffect(() => {
    if (data) {
      if (data.store === null) {
        history.push('/404');
      }
    }
    if (store.id) findValidUrl(store, phoneNumber, () => history.push('/404'));
  }, [data, store])

  useEffect(() => {
    if (storeTosData) {
      setStoreToS(storeTosData.store.termsAndConditions)
    }
  }, [storeTosData])

  useEffect(() => {
    const localAllItems = items.filter(item => item.amount !== 0)
    const objFromLS = localStorage.getItem('deliveryInfo')
    const objRec = JSON.parse(objFromLS)
    setDeliveryInfo(objRec)

    items.forEach(item => {
      item.extraVariants.forEach(variant => {
        localAllItems.push({
          sku: variant.sku,
          name: item.name,
          id: item.id,
          price: variant.price || item.price,
          amount: variant.amount,
          productOptions: variant.options.map(option => ({
            selected: option,
            productOptionValues: item.productOptions.find(
              productOption => productOption.productOptionValues
                .map(pov => pov.id)
                .indexOf(option) !== -1
            ).productOptionValues
          })),
          variantId: variant.variantId
        })
      })
    });

    const compare = (a, b) => {
      if (a.id < b.id) return -1
      if (a.id > b.id) return 1
      return 0;
    }

    setAllItems(localAllItems.sort(compare))

    handlePreviousStep()
  }, []);

  //Facebook pixel
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      store?.facebookPixelCode && ReactPixel.init(store?.facebookPixelCode);
      store?.facebookPixelCode && ReactPixel.track('InitiateCheckout', {
        content_type: 'checkout',
        content_name: items.map(item => item.name).join(', '),
        num_items: items.length,
      });
    }
  }, [items])
  /* EFFECTS HERE ⬆️ */
  
 

  const generalDataClass = classNames({
    [SendOrderStyles.generalStepOne]: currentStep === 1,
    [SendOrderStyles.generalStepTwo]: currentStep === 2,
  })
  const ShippingDataClass = classNames({
    [SendOrderStyles.generalStepOne]: currentStep === 2
  })

  const tagsStepGen = classNames({
    [SendOrderStyles.tagStepOne]: currentStep === 1,
    [SendOrderStyles.tagStepTwo]: currentStep === 2,
  })

  const tagsStepShi = classNames({
    [SendOrderStyles.tagStepOne]: currentStep === 2,
    [SendOrderStyles.tagStepTwo]: currentStep === 1,
  })
  
  const dividerClass = classNames({
    [SendOrderStyles.dividerStepOne]: currentStep === 1,
    [SendOrderStyles.dividerStepTwo]: currentStep === 2,
  })

  return (
    <>
      {loading && <CircularProgress style={{ margin: '1rem' }} />}
      {!loading && 
        <main className={SendOrderStyles.sendOrderContainer}>
          <figure>
            <IconButton
              onClick={goBack}
              color="inherit"
              aria-label="regresar"
            >
              <ChevronLeft fontSize="small" />
            </IconButton>
          </figure>
          <article className={SendOrderStyles.mainContainer}>
            <aside className={SendOrderStyles.stepsContainer}>
              <h1>{formData ? formData.title : 'Completa tu información'}</h1>
              <section className={SendOrderStyles.steps}>
                <span className={generalDataClass}>
                  <img src={exmpleimg} alt='step 1 image'/>
                </span>
                <p className={tagsStepGen}>Datos generales</p>
              </section>
              { existShippingInfo &&
                <>
                  <hr className={dividerClass}/>
                  <section className={SendOrderStyles.steps}>
                    <span className={ShippingDataClass}>
                      <img src={exmpleimg} alt='step 2 image'/>
                    </span>
                    <p className={tagsStepShi}>Datos de envío</p>
                  </section>
                </>
              }
              
            </aside>
            <aside className={SendOrderStyles.formContainer}>
              <form onSubmit={handleSubmit(onSubmit)} >
                { currentStep === 1 &&
                <>
                  <CustomFormFiels 
                    register={register}
                    code={code}
                    setCode={setCode}
                    opcQuestions={opcQuestions}
                    fieldsForm={fieldsForm}
                    formInfo={formInfo}
                    watch={watch}
                  />
                  
                  <div>
                    <CheckboxTerms
                      control={
                        <Checkbox
                          className={SendOrderStyles.checkbox}
                          {...register("termsCheck", { required: true })}
                          required
                          color="primary"
                        />
                      }
                      label={(
                        <>
                          <span>He leído y acepto <span>los <Link href="#" onClick={handleOpenTermsDialog}>Términos y Condiciones</Link> y <Link href="#" onClick={handleOpenPrivacyDialog}>Política de Privacidad</Link> de 10X y</span> {storeToS ? <span> las <Link href="#" onClick={handleOpenStoreTermsDialog}>Políticas de tratamiento de la información</Link> de {store.name}</span> : <span>las Políticas de tratamiento de la información de {store.name}</span>}</span>
                        </>
                      )}
                    />

                    <TermsAndConditions
                      open={openTermsDialog}
                      openHandler={handleOpenTermsDialog}
                      closeHandler={handleCloseTermsDialog}
                    />

                    <PrivacyPolicy
                      open={openPrivacyDialog}
                      openHandler={handleOpenPrivacyDialog}
                      closeHandler={handleClosePrivacyDialog}
                    />

                    <StoreTermsAndConditions
                      open={openStoreTermsDialog}
                      openHandler={handleOpenStoreTermsDialog}
                      closeHandler={handleCloseStoreTermsDialog}
                      storeToS={storeToS}
                    />
                  </div>
                </>
                }
                { currentStep === 2 && 
                  <ShippingWhatsApp store={store} register={register}/> 
                }
                <div className={SendOrderStyles.buttonsContainer}>
                  { currentStep === 1 && existShippingInfo
                    ? <Button
                        disabled={isValid ? false : true}
                        className={SendOrderStyles.sendOrderBtn}
                        onClick={handleNextStep}
                      >
                        Continuar
                      </Button>
                    :<>
                      { existShippingInfo &&
                        <Button
                          className={SendOrderStyles.backBtn}
                          onClick={handlePreviousStep}
                        >
                            Atrás
                        </Button>
                      }
                      < Button
                        disabled={isValid ? false : true}
                        type='submit'
                        className={SendOrderStyles.sendOrderBtn}
                        variant="contained"
                        startIcon={<Whatsapp fontSize="small" />}  
                      >
                        {store.callToActionButton || `Ordenar por WhatsApp`}
                        {sent &&
                          <CircularProgress
                            size={24}
                            className={SendOrderStyles.sendOrderBtnProgress}
                          />
                        }
                      </Button>
                    </>
                  }
                </div>
              </form>
            </aside>
          </article>
        </main>
      }
    </>
  )
}

// Customize the Autocomplete Component


// Customize the FormControlLabel Checkbox Component
function CheckboxTerms(props) {
  const classes = checkboxTermsStyles()
  return (
    <FormControlLabel classes={classes} {...props} />
  )
}

// Material-UI Styles


const checkboxTermsStyles = makeStyles(theme => ({
  root: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  label: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  }
}));

const mapStateToProps = ({ main }) => ({
  items: main.items,
  store: main.store,
});

//const mapDispatchToProps = dispatch => bindActionCreators({ setCartState }, dispatch);

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(SendOrder);