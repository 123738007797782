// ReactJS
import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form"

// External dependencies
import axios from 'axios'
import { 
    makeStyles, 
    OutlinedInput, 
    Button, 
    Select, 
    MenuItem, 
    FormControl, 
    FormLabel, 
    CircularProgress 
} from '@material-ui/core'

import { countries } from '../../../utils/constants'
import { getCities } from '../../../services/payments'

//Pixel FACEBOOK
import ReactPixel from 'react-facebook-pixel'

//styles
import checkoutStyle from '../checkout.module.scss'

const useStyles = makeStyles({
    typography: {
        fontSize: 22,
        display: 'flex',
        flexDirection: 'row',
        width: '80%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    
    labels: {
        fontSize: 16,
        fontWeight: 400,
        textAlign: 'left',
        color: '#0F2930',
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
});

const StepDelivery = ({ setStep, store, handleClick, orderShippingInfo, orderId }) => {
    const { register, handleSubmit, watch, setValue, formState: { isValid } } = useForm({
        mode: 'onChange',
    })
    const classes = useStyles()

    const [getCountry, setGetCountry] = useState(null)
    const [cities, setCities] = useState(null)
    const [defaultCityObj, setDefaultCityObj] = useState(null)
    const [loading, setLoading] = useState(false)
    const [departament, setDepartament] = useState([])
    const [departamentSel, setDepartamentSel] = useState('')
    const [citiesToSel, setCitiesToSel] = useState([])

    useEffect(() => {
        setLoading(true)
        getCities()
            .then(res => {
                setCities(res.data);
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            });
        const countryInCode = countries.find( country => country.phone === localStorage.getItem("PaymentIndexPhone"))
        setGetCountry( countryInCode?.label ? countryInCode?.label :  'Colombia'  )
    }, []);

    useEffect( ()=> {
        if (cities?.length > 0) {
            const dptos = cities.map(city => city.state)
            const uniqDep = [...new Set(dptos)]
            setDepartament(uniqDep.sort())
        }
        if (orderShippingInfo?.sel_city_id) {
            const fil = cities?.find( c => c.id === orderShippingInfo?.sel_city_id )
            setDepartamentSel(fil?.state)
            setDefaultCityObj(fil)
        }
    },[cities]) 

    useEffect( ()=> {
        const city = cities?.filter( city => city.state === departamentSel)
        setCitiesToSel(city)
        const verifyCity = watch('city') ? true : false
        if(!verifyCity) {
            setValue('city', defaultCityObj?.id)
        }
    },[departamentSel]) 

    console.log(watch('city'))
    const handleChangeCountry = (e) => {
        setGetCountry(e.target.value)
    }

    const selectedDepValue = (e) => {
        setDepartamentSel(e.target.value)
    }

    const onSubmit = (data) => {
        const shippData = {
            comments: data.comments,
            shipping_information: {
              address: data.address,
              address_detail: data.detailAddress,
              city: orderShippingInfo.delivery_city,
              country_code: 'CO',
              city_id: orderShippingInfo.sel_city_id,
              store_pickup_enabled: orderShippingInfo.shipping_method === 'Recoger en tienda' ? true : false,
              cash_on_delivery_enabled: orderShippingInfo.cash_on_delivery ,
              collection_point_id: orderShippingInfo.branch.id,
              other_zone: !orderShippingInfo.delivery_city ? true : false
            }
          }
          axios.put(`${process.env.REACT_APP_API_URL}/wa10x/stores/${store.id}/orders/${orderId}/shipping-info`, shippData)
          .then(res =>{
            localStorage.setItem("Step", 3);
            const { address, city, departament, comments, country, detailAddress } = data
            const nameCity = cities?.find(e => e?.id === parseInt(city))
            localStorage.setItem("StepDelivery", JSON.stringify({
                address: address,
                city: nameCity?.name,
                departament: departamentSel,
                city_id: parseInt(city),
                comments: comments,
                country: getCountry,
                detailAddress: detailAddress
            }));
            setStep(3)
            ReactPixel.track('Lead', {
                content_name: 'Continuar a pagos',
            })
          })
          .catch( err => console.log(err))
        
        
    };
   
    //Facebook pixel
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            store?.facebookPixelCode && ReactPixel.init(store?.facebookPixelCode);
            store?.facebookPixelCode && ReactPixel.track('InitiateCheckout', {
                content_type: 'step delivery',
            })
        }
    }, [store])
    
    return (
        <>
            {loading && <CircularProgress style={{ margin: '1rem' }} />}
            {!loading && 
                <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                    <FormControl
                        variant="outlined"
                        margin="normal"
                        fullWidth>
                        <FormLabel htmlFor='country' className={classes.labels} style={{ paddingBottom: '7px' }}>
                            País
                        </FormLabel>
                        <Select
                            {...register('country')}
                            defaultValue={getCountry}
                            style={{ textAlign: 'initial' }}
                            id='country'
                            input={<OutlinedInput label="country" fullWidth />}
                            onChange={handleChangeCountry}
                        >
                            {countries?.map(cou => (
                                <MenuItem value={cou.label} key={cou.code}>
                                    {cou.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    { getCountry === 'Colombia' && (
                        <>
                            <FormControl
                                variant="outlined"
                                margin="normal"
                                fullWidth>
                                <FormLabel htmlFor='departament' className={classes.labels}>
                                    Departamento
                                </FormLabel>
                                <Select
                                    style={{ textAlign: 'initial' }}
                                    id='departament'
                                    onChange={selectedDepValue}
                                    value={departamentSel}
                                    input={<OutlinedInput label="departament" fullWidth />}
                                >
                                    {departament?.map(dep => (
                                        <MenuItem value={dep} key={dep}>
                                            {dep}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl
                                disabled={!departamentSel}
                                variant="outlined"
                                margin="normal"
                                fullWidth>
                                <FormLabel htmlFor='city' className={classes.labels}>
                                    Ciudad
                                </FormLabel>
                                <Select
                                    {...register('city', { required: true })}
                                    style={{ textAlign: 'initial' }}
                                    id='city'
                                    defaultValue={defaultCityObj?.id}
                                    input={<OutlinedInput label="city" fullWidth input/>} 
                                >
                                    {/* {colombianCities.map((item, index) => (
                                    <MenuItem value={item?.city} key={index}>
                                        {item?.city}
                                    </MenuItem>
                                ))} */}
                                    {citiesToSel?.map((city) => (
                                        <MenuItem value={city?.id} key={city?.id}>
                                            {city?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </>
                    )}
                    <FormControl
                        variant="outlined"
                        margin="normal"
                        fullWidth>
                        <FormLabel htmlFor='address' className={classes.labels}>
                            Dirección
                        </FormLabel>
                        <OutlinedInput
                            {...register('address', { required: true })}
                            id='address'
                            defaultValue={JSON.parse(localStorage.getItem("StepDelivery"))?.address}
                            fullWidth
                        />
                    </FormControl>
                    <FormControl
                        variant="outlined"
                        margin="normal"
                        fullWidth>
                        <FormLabel htmlFor='detailAddress' className={classes.labels}>
                            Detalles de la dirección
                        </FormLabel>
                        <OutlinedInput
                            {...register('detailAddress')}
                            id='detailAddress'
                            defaultValue={JSON.parse(localStorage.getItem("StepDelivery"))?.detailAddress}
                            type='text'
                            placeholder={ getCountry === 'Colombia' ? 'Apto, Local, Oficina...' : 'Ciudad, Estado, Provincia' }
                            fullWidth
                        />
                    </FormControl>
                    <FormControl
                        variant="outlined"
                        margin="normal"
                        fullWidth>
                        <FormLabel htmlFor='comments' className={classes.labels}>
                            Comentarios
                        </FormLabel>
                        <OutlinedInput
                            {...register('comments')}
                            id='comments'
                            defaultValue={JSON.parse(localStorage.getItem("StepDelivery"))?.comments}
                            placeholder="Notas o instrucciones de entrega"
                            rows={3}
                            fullWidth
                            multiline
                        />
                    </FormControl>
                    <div className={checkoutStyle.buttonsContainer}>
                        <Button
                        onClick={handleClick}
                            className={checkoutStyle.backBtn}
                            variant="contained"
                            fullWidth
                        >
                            Atrás
                        </Button>
                        <Button
                            disabled={isValid ? false : true}
                            className={checkoutStyle.continueBtn}
                            variant="contained"
                            color='primary'
                            type='submit'
                            fullWidth
                        >
                            Continuar
                        </Button>
                    </div>
                </form>
            }
        </>
    );
}

export default StepDelivery;