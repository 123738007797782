import { getLocalData, setLocalData } from "../utils/constants";

export const SET_CART = 'cart/SET_CART';
export const SET_STORE = 'cart/SET_STORE';
export const SET_HOME_SCROLL_POSITION = 'cart/SET_HOME_SCROLL_POSITION';

let defaultData = {
  items: [],
  store: {}
};

const paths = window.location.pathname.split('/').filter(path => path.length);

defaultData = getLocalData();
/* if(paths.length >= 2) {
} */

const initialState = {
  items: defaultData.items,
  store: defaultData.store,
  //homeScrollPosition: 200
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CART:
      return {
        ...state,
        items: action.payload,
      }

    case SET_STORE:
        return {
          ...state,
          store: action.payload,
        }
    
    // case SET_HOME_SCROLL_POSITION:
    //     return {
    //       ...state,
    //       homeScrollPosition: action.payload,
    //     }

    default:
      return state
  }
}

const setData = (actionName, payload) => {
  return dispatch => {
      dispatch({ 
          type: actionName, 
          payload: payload
      });
  }
}

export const setCartState = (storeUrl, phoneNumber, cartData, store) => {
  setLocalData(storeUrl, phoneNumber, {
    items: cartData,
    store: store
  });
  return setData(SET_CART, cartData);
};

export const setStoreState = (storeUrl, phoneNumber, cartData, store) => {
  setLocalData(storeUrl, phoneNumber, {
    items: cartData,
    store: store
  });
  return setData(SET_STORE, store);
};

// export const setHomeScrollPosition = (position) => setData(SET_HOME_SCROLL_POSITION, position); 

