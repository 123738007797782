import React, { useState, useEffect } from 'react'
import { useHistory, Link as RouterLink } from 'react-router-dom'
import axios from 'axios'

import { IconSearch } from '../../../assets/icons/search-icon'
import { IconCart } from '../../../assets/icons/cart'
import { IconClose } from '../../../assets/icons/close'

import HeaderStyles from '../Header.module.scss'

const SearchBar = ({ cartUrl, cartItems, shopLogoImageSrc, shopTitle, productSubUrl, storeId, goBackToStoreUrl }) => {
    const history = useHistory();

    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState([])
    const [search, setSearch] = useState(false)
    
    const goToHome = () => {
        history.push(goBackToStoreUrl)
    }
    const goToCart = () => {
        history.push(cartUrl)
    }
    const handleToggleInput = () => setSearch(!search)

    useEffect( () => {
        if(inputValue.length >= 3) {
            axios.get(`${process.env.REACT_APP_API_URL}/stores/${storeId}/products/public-search?term=${inputValue}`)
            .then(res => setOptions(res.data))
        }
        if(inputValue.length === 0) {
            setOptions([])
        }
    },[inputValue] )
    return(
        <article className={HeaderStyles.nav}>
        <section className={HeaderStyles.logoContainer} onClick={goToHome} style={{ cursor: goBackToStoreUrl && 'pointer'  }}>
          <div className={HeaderStyles.imageContainer}>
            <img className={HeaderStyles.navImg} src={shopLogoImageSrc} alt='Shop image'/>
          </div>
          <p>{shopTitle}</p>
        </section>
        <section className={HeaderStyles.logoContainer}>
          <div  className={HeaderStyles.navBtnSearch} >
            <IconSearch 
              className={`${HeaderStyles.navBtn} ${search && HeaderStyles.expandedNavBtn}`}
              style={{ width: 20, height: 20, }}
              onClick={ !search && handleToggleInput}
            />
            <input
              type="text"
              className={`${HeaderStyles.searchInput} ${search && HeaderStyles.expandedInput}`}
              onFocus={ !search && handleToggleInput}
              onBlur={!inputValue && handleToggleInput}
              placeholder={search && `Buscar en ${shopTitle}`}
              onChange={ (e) => setInputValue(e.target.value) }
              value={inputValue}
            />
            { search && 
                <IconClose 
                  className={HeaderStyles.closeIcon}
                  onClick={ () => {
                    setInputValue('')
                    handleToggleInput()
                  } }
                />
            }
            { search &&
                <div  className={HeaderStyles.resultsContainer}>
                    { inputValue.length < 3 && options?.length === 0 &&
                        <p >Escribe al menos 3 caracteres para iniciar una búsqueda</p>
                    }
                        { options === null && inputValue.length > 0
                            ? <p>no hay resultados</p>
                            : <section className={HeaderStyles.cardsContainer} >
                                { options?.map( (opt, index) => (
                                    <RouterLink
                                    className={HeaderStyles.searchCard}
                                    to={`${productSubUrl}/${opt.url}`}
                                    style={ index === options.length - 1 ? { borderBottom: 'none', padding: 0 } : {}}
                                    >
                                        { opt.picture_url 
                                            ? <img src={`${process.env.REACT_APP_IMAGES_API_URL}/fit-in/84x84/${opt.picture_url}`} alt={opt.name}/>
                                            : <img  src={`/10x-product.jpg`} alt={opt.name} style={{ width: 84, height: 84 }}/>
                                        }
                                    
                                        <p >{opt.name}</p>
                                    </RouterLink>
                                ) ) }
                            </section>
                        }
                </div>
            }
          </div>
          {
            !cartItems &&
                <div 
                className={HeaderStyles.navBtnCart}
                onClick={goToCart}
                >
                    <IconCart className={HeaderStyles.navBtn}/>
                </div>
            }
        </section>
      </article>
    )
}

export default SearchBar