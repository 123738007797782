/* eslint-disable react-hooks/exhaustive-deps */

// React dependencies
import React, { useState, useEffect, useRef } from 'react';

// External dependencies
import classNames from 'classnames'
import {
  IconButton
} from '@material-ui/core';

// assets
import ChevronRight from '../Icons/ChevronRight';
import ChevronLeft from '../Icons/ChevronLeft';

// styles
import CategoryGroupStyles from './CategoryGroup.module.scss'

const CategoryGroup = ({ children, categoriesQty, isInactive, title }) => {
  const [hideArrow, setHideArrow] = useState({left: true, right: false});

  // ref for the category list
  const categoryListRef = useRef(null);
  const categoryListWidth = categoryListRef?.current?.scrollWidth - 1100
  const currentWidth = Math.ceil(categoryListRef?.current?.scrollLeft)

  // get window width
  //const getWindowInnerWidth = window.innerWidth;

  const galleryLeftArrowClass = classNames({
    [CategoryGroupStyles.arrow]: true,
    [CategoryGroupStyles.arrowLeft]: true,
    [CategoryGroupStyles.arrowHide]: hideArrow.left
  });

  const galleryRightArrowClass = classNames({
    [CategoryGroupStyles.arrow]: true,
    [CategoryGroupStyles.arrowRight]: true,
    // [CategoryGroupStyles.arrowHide]: hideArrow.right
  });
  
  const scrollShowArrow = ({ target }) => {
    if (target.scrollLeft > 120) {
      setHideArrow({ left: false, right: true });
    } else {
      setHideArrow({ left: true, right: false });
    }
  }

  const scrollListToLeft = () => {
    categoryListRef.current.scrollBy({
      left: -460,
      behavior: 'smooth',
    });
  }

  const scrollListToRight = () => {
    categoryListRef.current.scrollBy({
      left: 420,
      behavior: 'smooth',
    });
  }

useEffect(() => {
  const activeItem = categoryListRef.current.querySelector('[class*="Category_active"], [class*="active"]')

  if (activeItem) {
    const containerWidth = categoryListRef.current.clientWidth
    const activeItemLeft = activeItem.offsetLeft

    categoryListRef.current.scrollLeft = activeItemLeft - containerWidth / 2
  }
}, [])

  return (
    <section style={isInactive ? {
      opacity: 0.5,
      pointerEvents: 'none'
    } : {}} className={CategoryGroupStyles.wrapper}
    >
      <div
        className={CategoryGroupStyles.list}
        onScroll={scrollShowArrow}
        ref={categoryListRef}
      >
        {children}
      </div>
      { (categoriesQty > 5) && 
        <IconButton
          className={galleryLeftArrowClass}
          onClick={scrollListToLeft}
          color="default"
          aria-label="Mostrar más categorías"
        >
        <ChevronLeft fontSize="small" />
        </IconButton>
      }
      { (categoriesQty > 5 && currentWidth < categoryListWidth ) && 
        <IconButton
          className={galleryRightArrowClass}
          onClick={scrollListToRight}
          color="default"
          aria-label="Mostrar más categorías"
        >
        <ChevronRight fontSize="small" />
        </IconButton>
      }
      <hr className={CategoryGroupStyles.divider}/>
      <div className={CategoryGroupStyles.title}>
        <h1>{title === 'todo' ? 'Todos los productos' : title }</h1>
      </div>
    </section>
  )
}

export default CategoryGroup
