import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      colorTransparent: {
        boxShadow: 'none',
      },
    },
    MuiPaper: {
      rounded: {
        border: 'none',
        boxShadow: '0px 0px 1px rgba(52,58,67,0.75)',
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundImage: 'linear-gradient(85deg, #a2f072 0%, #9EE870 100%)',
        color: '#173300',
        '&.Mui-disabled': {
          backgroundImage: 'none',
        },
      },
      contained: {
        '&:hover': {
          '&.Mui-disabled': {
            backgroundColor: '#D8D8D8',
          },
        },
        '&.Mui-disabled': {
          backgroundColor: '#D8D8D8',
        },
      },
      textSizeSmall: {
        fontSize: '0.75rem', // 12px
        padding: '8px 10px',
      },
    },
    MuiIconButton: {
      root: {
        padding: '8px',
      },
    },
    MuiFormLabel: {
      root: {
        transition: 'color 150ms cubic-bezier(0.4, 0, 1, 1)',
      },
    },
    MuiOutlinedInput: {
      root: {
        boxShadow: '0 0 2px rgba(52,58,67,.1),0 1px 2px rgba(52,58,67,.08),0 1px 4px rgba(52,58,67,.08)',
        'label + &': {
          marginTop: '0.5rem',
        },
        '&:focus': {
          boxShadow: '0px 0px 2px rgba(52, 58, 67, 0.1), 0px 2px 5px rgba(52, 58, 67, 0.08), 0px 5px 15px rgba(52, 58, 67, 0.08)'
        }
      },
      adornedStart: {
        paddingLeft: '0px !important'
      },
      input: {
        padding: '14.5px 14px !important',
        background: '#ffffff',
        borderRadius: 8,
        position: 'relative',
        webkitAppearance: 'none',
        webkitBoxShadow: '0px 0px 2px rgba(52,58,67,0.10),0px 1px 2px rgba(52,58,67,0.08),0px 1px 4px rgba(52,58,67,0.08) !important',
        boxShadow: '0px 0px 2px rgba(52,58,67,0.10),0px 1px 2px rgba(52,58,67,0.08),0px 1px 4px rgba(52,58,67,0.08) !important',
        transition: 'box-shadow 150ms cubic-bezier(0.4, 0, 1, 1)',
        '&:invalid': {
          boxShadow: '0px 0px 2px rgba(52,58,67,0.10),0px 1px 2px rgba(52,58,67,0.08),0px 1px 4px rgba(52,58,67,0.08)',
        },
        '&:focus': {
          boxShadow: '0px 0px 2px rgba(52,58,67,0.10),0px 2px 5px rgba(52,58,67,0.08),0px 5px 15px rgba(52,58,67,0.08)',
        }
      },
      notchedOutline: {
        display: 'none'
      },
      multiline: {
        padding: '0',
        textarea: {
          padding: '14.5px 14px',
        }
      }
    },
    MuiLink: {
      root: {
        fontWeight: '700',
        '&:focus': {
          outlineColor: '#266678',
        },
      }
    },
    MuiSelect: {
      select: {
        borderRadius: 8,
      }
    }
  },
  palette: {
    type: 'light',
    primary: {
      light: '#73baa9',
      main: '#9EE870',
      dark: '#3a8a7a',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#5c8c9c',
      main: '#266678',
      dark: '#1b4754',
    },
    error: {
      light: '#d49494',
      main: '#cb7c7a',
      dark: '#a26362',
    },
    warning: {
      light: '#dab47d',
      main: '#cda35f',
      dark: '#b99356',
    },
    success: {
      light: '#73baa9',
      main: '#48ac98',
      dark: '#3a8a7a',
    },
    text: {
      primary: "#0f2930",
      secondary: "#53627c",
      disabled: "#a6aebc",
      hint: "#a6aebc",
    },
    background: {
      paper: '#ffffff',
      default: '#fafafa',
    },
  },
  shadows: [
    'none',
    '0px 0px 2px rgba(52,58,67,0.10),0px 1px 2px rgba(52,58,67,0.08),0px 1px 4px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 2px 5px rgba(52,58,67,0.08),0px 5px 15px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 5px 10px rgba(52,58,67,0.08),0px 15px 35px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 10px 20px rgba(52,58,67,0.08),0px 20px 40px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
  ],
  typography: {
    fontFamily: [
      '"Myriad semi bold"',
      '"Myriad bold"',
      '"Myriad regular"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: 'Myriad bold',
      textAlign: 'center',
      margin: '0 0 20px',
      fontSize: '2.75rem',
      fontWeight: 500,
    },
    h2: {
      fontFamily: 'Myriad bold',
      fontSize: '1.75rem', //28px
    },
    h3: {
      fontSize: '1.625rem', // 26px
    },
    h4: {
      fontSize: '1.5rem', // 24px
    },
    h5: {
      fontSize: '1.25rem', // 20px
    },
    h6: {
      fontSize: '1rem', // 16px
    },
    subtitle1: {
      fontSize: '1rem', // 16px
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '0.875rem', // 14px
      fontWeight: 400,
    },
    body1: {
      fontSize: '1rem', // 16px
      fontWeight: 500,
      fontFamily: 'Myriad semi bold'
    },
    body2: {
      fontSize: '0.875rem', // 14px
      fontFamily: 'Myriad semi bold',
      fontWeight: 500
    },
    button: {
      textTransform: 'none',
      fontSize: '1.125rem', // 18px
      fontFamily: 'Myriad bold',
      fontWeight: 600,
    },
    caption: {
      fontSize: '0.875rem', // 14px
      fontFamily: 'Myriad regular',
      fontWeight: 500,
    },
    overline: {
      fontSize: '0.875rem', // 14px
      fontFamily: 'Myriad regular',
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 8
  },
})

export default theme
