import React from 'react'
import { SvgIcon } from '@material-ui/core'

const ChevronUp = props => {
  return (
    <SvgIcon {...props}>
      <path d="M3.85,17l8.1-7.7,8.1,7.6a1.2215,1.2215,0,0,0,.9.4,1.7969,1.7969,0,0,0,.9-.4,1.7969,1.7969,0,0,0,.4-.9,1.0858,1.0858,0,0,0-.4-.9l-8.5-8a2.3893,2.3893,0,0,0-1.4-.5,1.6125,1.6125,0,0,0-1.3.6l-8.5,8a1.4893,1.4893,0,0,0-.4.9,1.2215,1.2215,0,0,0,.4.9,1.2215,1.2215,0,0,0,.9.4A2.01,2.01,0,0,0,3.85,17Z"/>
    </SvgIcon>
  )
}

export default ChevronUp
