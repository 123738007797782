/* eslint-disable react-hooks/exhaustive-deps */

// React dependencies
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, useParams } from 'react-router-dom';

// External dependencies
import classNames from 'classnames/bind';
import { useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { Carousel } from 'react-responsive-carousel';
import difference from 'lodash/difference';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { hotjar } from 'react-hotjar';

// Material UI dependencies
import {
  Button,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Link from '@material-ui/core/Link';

// Internal components
import ProductMedia from '../ProductMedia'
import ShareButton from '../ShareButton'
import SearchBar from '../Header/search-bar/search-bar'

// Internal dependencies
import { setCartState, setStoreState } from '../../modules/main';
import { PRODUCT_BY_SLUG_AND_PHONE_QUERY } from '../../qgl-queries/product';
import { STORE_BY_URL_QUERY } from '../../qgl-queries/store';
import { findValidUrl, formatNumber } from '../../utils/constants';

//Assets 
import Donwload from '../../assets/icons/Donwload'
import ChevronDown from '../../assets/icons/ChevronDown'
import ChevronUp from '../../assets/icons/ChevronUp'

//Pixel FACEBOOK
import ReactPixel from 'react-facebook-pixel';

//Google Analitycs
import ReactGA from "react-ga4";

// Component styles
import ProductStyles from './Product.module.scss'
import { IconAddCart } from '../../assets/icons/add-to-cart';
import ZoomImage from '../ZoomImage';


/**
 * Renders the product page
 * @param {Function} setCartState setCartState from store
 * @param {Array} items cart items from store
 * @param {Object} store store object from store
 */
const Product = ({ setStoreState, setCartState, items, store }) => {
  const shopLogoImageSrc = store.profilePicture
                             ? (`${process.env.REACT_APP_IMAGES_API_URL}/fit-in/500x500/${store.profilePicture}`)
                             : (`https://ui-avatars.com/api/?name=${store.name || `10X&length=3`}&size=90&background=266678&color=fff`);

  const { /* storeUrl, */ phoneNumber, productSlug } = useParams();
  const storeUrl = window.location.hostname.split(".")[0]
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  
  // State variables
  const [beforeCartLoading, setBeforeCartLoading] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [outOfStock, setOutOfStock] = useState(false)
  const [productAdditonalData, setProductAdditonalData] = useState([])
  const [productExpandInfo, setProductExpandInfo] = useState([])
  const [productFiles, setProductFiles] = useState(null)
  const [productOptions, setProductOptions] = useState([])
  const [productPrice, setProductPrice] = useState(0)
  const [selVariant, setSelVariant] = useState(undefined)
  const [singleProductAdded, setSingleProductAdded] = useState(false)
  const [indexInfo, setIndexInfo] = useState(null)
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [productData, setProductData] = useState({
    name: '',
    price: '',
    description: '',
    productAttachments: [],
    productImages: [],
    productOptions: [],
    productVarianst: [],
    active: 1,
  })

  const handleImageClick = (image) => {
    setSelectedImage(image)
    setDialogOpen(true)
  }

  const slideImage = (action) => {
    if(action === 'prev'){
      const ind = imageIndex - 1
      const img = productData.productImages[ind].pictureUrl
      setSelectedImage(img)
    }else{
      const ind = imageIndex + 1
      const img = productData.productImages[ind].pictureUrl
      setSelectedImage(img)
    }
  }
  const imageIndex = productData?.productImages?.findIndex( img => img.pictureUrl === selectedImage )
  const imageLength = productData?.productImages?.length
 
  /* EFFECTS HERE ⬇️ */
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      hotjar.initialize(1702922, 6);

      /* Google Analytics */
      if (store?.googleAnalitycsTrackingId) {
        ReactGA.initialize([
          {
            trackingId: store?.googleAnalitycsTrackingId
          },
          {
            trackingId: 'UA-163783687-1'
          }
        ]);
      }
      else {
        ReactGA.initialize([
          {
            trackingId: 'UA-163783687-1'
          }
        ])
      }
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
      
      /* Pixel de Facebook */
      if(store?.facebookPixelCode) {
        store?.facebookPixelCode && ReactPixel.init(store?.facebookPixelCode)
        store?.facebookPixelCode && ReactPixel.track('ViewContent', {
          content_name: productData.name,
          value: productData.price,
          currency: store.currency
        })
      }
    }
  }, []);
  
  // Product query
  const { loading, error, data } = useQuery(PRODUCT_BY_SLUG_AND_PHONE_QUERY, {
    variables: {
      slug: productSlug,
      phoneNumber: phoneNumber
    }
  });

  // Store query
  const {
    data: storeData
  } = useQuery(STORE_BY_URL_QUERY, {
    variables: { url: storeUrl },
    skip: store.id ? true : false
  });

  /**
   * Adds the current product to the cart
   */
  const addProductToCart = () => {
    setBeforeCartLoading(true);

    const productInCart = items.findIndex(item => item.id === productData.id);
    // If the product is already in the cart update the product's product options

    // Find the variant with the given options
    const optionsIds = productOptions.map(option => option.selected);
    const selectedVariant = productData.variants.find(variant => {
      return difference(optionsIds, variant.optionValues.map(value => value.id)).length === 0;
    });

    const variantPrice = selectedVariant ? selectedVariant.discountPrice || selectedVariant.price : null;
    const productPrice = productData.discountPrice || productData.price;
    let newCartData;

    if (productInCart >= 0) {
      newCartData = items.map((item, index) => {
        if (productInCart === index) {
          // check if original item match the selected variant
          const isOriginalVariant = selectedVariant ? item.variantId === selectedVariant.id : false;
          // chek if variant is inside the extraVariants array
          let extraVariants = item.extraVariants.map(variant => {
            let amount = variant.amount;
            if (selectedVariant) {
              amount = variant.variantId === selectedVariant.id ? variant.amount + 1 : variant.amount;
            }
            return {
              ...variant,
              amount: amount,
              active: 1,
            }
          });

          const extraVariantExists = item.extraVariants.find(variant => variant.variantId === selectedVariant.id);

          if (!extraVariantExists && selectedVariant && !isOriginalVariant) {
            extraVariants = [...extraVariants, {
              amount: 1,
              variantId: selectedVariant.id,
              sku: selectedVariant.sku,
              options: optionsIds,
              price: selectedVariant ? selectedVariant.discountPrice || selectedVariant.price || productData.discountPrice || productData.originalPrice : productData.discountPrice || productData.originalPrice,
              active: 1,
            }]
          }

          return {
            ...item,
            amount: isOriginalVariant ? item.amount + 1 : item.amount,
            active: 1,
            extraVariants
          }

        } else {
          return {
            ...item
          }
        }
      })
    } else {
      newCartData = [...items, {
        amount: 1,
        extraVariants: [],
        id: productData.id,
        image: productData.productImages.length > 0
          ? process.env.REACT_APP_IMAGES_API_URL + '/fit-in/96x96/' + productData.productImages[0].pictureUrl
          : '',
        imageSrcSet: productData.productImages > 0
          ? (`${process.env.REACT_APP_IMAGES_API_URL}/fit-in/96x96/${productData.productImages[0].pictureUrl} 1x, 
            ${process.env.REACT_APP_IMAGES_API_URL}/fit-in/192x192/${productData.productImages[0].pictureUrl} 2x, 
            ${process.env.REACT_APP_IMAGES_API_URL}/fit-in/288x288/${productData.productImages[0].pictureUrl} 3x`)
          : '',
        name: productData.name,
        originalPrice: productData.price,
        price: productData.price > 0 ? variantPrice || productPrice : 0,
        productOptions: productOptions,
        showMoreOptions: false,
        sku: productData.sku,
        variantId: selectedVariant ? selectedVariant.id : null,
        variants: productData.variants,
        variantsPrice: 0,
        active: 1,
      }];
    }
    
    //setVariantIdSelected(selectedVariant ? selectedVariant.id : null);
    setCartState(storeUrl, phoneNumber, newCartData, store);

    // Redirect to the cart page
    setTimeout(() => history.push(`/${phoneNumber}/order/products?ref=${nameCollection ? nameCollection : ''}`), 100);
  }

  const handleHaveStore = () => {
    const hasOptions = data.product.variants.length > 0;

    let hasAvailability

    if (hasOptions) {
      // Find the variant with the given options
      const optionsIds = productOptions.map(option => option.selected);
      const selectedVariant = productData.variants.find(variant => {
        return difference(optionsIds, variant.optionValues.map(value => value.id)).length === 0;
      });

      hasAvailability = data.product.variants.find(opt => opt.id === selectedVariant.id).quantity === 0 ? false : true;
    } else {
      hasAvailability = data.product.quantityAvailable === 0 ? false : true;
    }

    if (hasAvailability === false || !data.product.active) {
      setOutOfStock(true);
      enqueueSnackbar('Producto agotado', {
        variant: 'info',
        autoHideDuration: 6000
      });
      
      setTimeout(() => {
        setOutOfStock(false);
      }, 1500);
    }
    else {
      setOutOfStock(false);
      addProductToCart();
    }
  }

  const handleButtonDisabledScroll = () => {
    if (!isValid) {
      window.scroll({
        top: window.innerHeight,
        behavior: 'smooth',
      });
    }
  }

  /**
   * Handle a product option selection
   * @param {number} optionIndex index of the options in the array
   * @param {number} value new value
   */
  const setSelectedOption = (optionIndex, value, singleAdded) => {
    setProductOptions(productOptions.map((option, index) => ({
      ...option,
      selected: optionIndex === index ? value || '' : option.selected
    })))
    if (singleAdded) setSingleProductAdded(true);
  }

  const toggleMoreInfo = (i) => {
    if (i !== indexInfo) {
      setIndexInfo(i)
    }else{
      setIndexInfo(null)
    }
  }

  /* EFFECTS HERE ⬇️ */
  // The form is valid only if all the productOptions are selected
  useEffect(() => {
    const allOptionsSelected = productOptions.filter(option => option.selected.length > 0).length === productOptions.length;
    let formIsValid = productOptions.length > 0 ? allOptionsSelected : true;
    setIsValid(formIsValid);
    
    if (productOptions && productData.variants) {
      // Find the variant with the given options
      const optionsIds = productOptions.map(option => option.selected);
      const selectedVariant = productData.variants.find(variant => {
        return difference(optionsIds, variant.optionValues.map(value => value.id)).length === 0;
      })
      setSelVariant(selectedVariant)
      setProductPrice((selectedVariant && selectedVariant.price) ? selectedVariant.price : productData.price);
    }

    if (singleProductAdded) addProductToCart();
  }, [productOptions, singleProductAdded])

  // Set product data and options after graphql response
  useEffect(() => {
    if (data) {
      if (data.product) {
        document.title = data.product.name;
        setProductData({ ...data.product });
        setProductAdditonalData(data.product.productAttachments.length > 0 ? [data.product.productAttachments[0]].concat(data.product.productAdditionalInfo) : data.product.productAdditionalInfo)
        setProductPrice(data.product.price);
        const haveStockInVariants = data.product.variants.filter( v => v.quantity === null || v.quantity >= 1)
        const variantsWithStock = haveStockInVariants?.map( e => e.optionValues).flat()
        
        if (data.product.productOptions.length > 0) {
          setProductOptions(data.product.productOptions.map(option => ({
            ...option,
            selected: option.productOptionValues.length === 1 ? option.productOptionValues[0].id : ''
          })));
        }
        if (data.product.variants.length > 0) {
          if (haveStockInVariants?.length === 0) {
            setOutOfStock(true);
            enqueueSnackbar('Producto agotado', {
              variant: 'info',
              autoHideDuration: 3000
            });
          }
        }
        //retirar cuando el endPoint del buscador este correcto
        if (!IsActive) {
          enqueueSnackbar(`El producto ${data.product.name} no está disponible en este momento`, {
            variant: 'error',
            autoHideDuration: 6000
          });
        }
      }
    }
  }, [data])
  // Save store data in the state and local storage using the setStoreState function
  useEffect(() => {
    if (!store.id && storeData) {
      if (storeData.store) {
        setStoreState(storeUrl, phoneNumber, items, {
          ...storeData.store,
          storeCollections: storeData?.store?.storeCatalogs[0]?.collections,
        });
      }
    }
    if (store.id) {
      // If the url is not valid redirect the user to the 404 page
      findValidUrl(store, phoneNumber, () => history.push('/404'));
    }
  }, [store, storeData])

  // Handle graphql error
  useEffect(() => {
    if (error) {
      enqueueSnackbar('No pudimos recuperar los datos del producto, inténtalo mas tarde', {
        variant: 'error',
        autoHideDuration: 6000
      })
    }
  }, [error])

  useEffect(() => {
    const files = productAdditonalData.find( fl => fl.fileUrl )
    const expand = productAdditonalData.filter( fl => !fl.fileUrl )
    setProductFiles(files)
    setProductExpandInfo(expand)
  }, [productAdditonalData])

  useEffect(() => {
    setTimeout(window.scrollTo(0, 0), 2500);
  }, [])
  
  /* EFFECTS HERE ⬆️ */

  const carouselSectionClass = classNames({
    [ProductStyles.carouselSection]: true,
    [ProductStyles.loading]: loading || error
  });

  const nameCollection = history.location.search.slice(5)
  const IsActive = data?.product?.active //retirar cuando el endPoint del buscador este correcto

  return (
    <main className={ProductStyles.mainContainer}>
      <SearchBar 
        storeId={store.id}
        shopLogoImageSrc={shopLogoImageSrc}
        shopTitle={store.name}
        cartUrl={`/${phoneNumber}/order/products`}
        productSubUrl={`/${phoneNumber}`}
        cartItems={0}
        goBackToStoreUrl={`/${phoneNumber}/collections/${nameCollection && nameCollection}`}
      />
      <article className={ProductStyles.productContent}>
        <section className={ProductStyles.productTitleContainer}>
          <span> {nameCollection ? nameCollection.toLowerCase() : 'Todos los productos'} / <p> {productData.name}</p></span>
          <h1>{productData.name}</h1>
        </section>
        <section className={ProductStyles.productInfoContainer}>
          <aside className={ProductStyles.asideLeft}>
            <div className={carouselSectionClass}>
              <Carousel
                showArrows={true}
                showStatus={false}
                showIndicators={true}
                showThumbs={true}
                emulateTouch={true}
                dynamicHeight={false}
                swipeable={true}>
                {productData.productImages.length > 0
                  ?
                  productData.productImages.map(productImage => (
                    <ProductMedia
                      key={productImage.id}
                      image={productImage.pictureUrl}
                      imageSrcSet={productImage.imageSrcSet}
                      alt={productData.name} 
                      action={handleImageClick}
                    />
                  ))
                  :
                  <ProductMedia
                    alt={store.name} />
                }
              </Carousel>
            </div>
            <ZoomImage
              isOpen={isDialogOpen}
              onClose={() => setDialogOpen(false)}
              image={selectedImage}
              slideImage={slideImage}
              imageIndex={imageIndex}
              imageLength={imageLength}
            />
            <div className={ProductStyles.thumbContainerList}>
              { productData.productImages.length > 1 &&
                productData.productImages.map( productImage => (
                  <div className={ProductStyles.thumbContainer}>
                    <ProductMedia
                      key={productImage.id}
                      image={productImage.pictureUrl}
                      imageSrcSet={productImage.imageSrcSet}
                      alt={productData.name}
                      action={handleImageClick}
                    />
                  </div>
                ) ) }
            </div>
          </aside>
          <aside className={ProductStyles.asideRight}>
          <div className={ProductStyles.productShareBtn}>
            <ShareButton
              title={store.name}
              text={productData.name} />
          </div>
            <p>{productData.sku}</p>
            {(productData.price || selVariant?.price > 0) &&
              <div className={ProductStyles.priceContainer}>
                <h1>
                  {formatNumber( selVariant?.discountPrice ? selVariant.discountPrice : productData.discountPrice , store.currency)}
                </h1>
                {((productData.discountPrice && !selVariant) || (selVariant?.discountPrice !== null && selVariant?.discountPrice > 0 )) &&
                  <p className={ProductStyles.withDiscount} >
                    {formatNumber(productPrice, store.currency)}
                  </p>
                }
              </div>
            }
            {productData.description && (
              <p 
                dangerouslySetInnerHTML={{ __html: productData.description }}
                className={ProductStyles.description}
              />
            )}
            <hr className={ProductStyles.divider}/>
            {
            // (productOptions.length > 0 && !hideProductOptions) &&
              <div translate='no' className={ProductStyles.optionListContainer}>
                { productOptions.map((option, optionIndex) =>
                  <React.Fragment key={option.id}>
                    <h2>
                      Selecciona {option.name}
                    </h2>
                    <ToggleButtonGroup
                      exclusive
                      value={option.selected}
                      onChange={(e, value) => setSelectedOption(optionIndex, value)}
                      className={ProductStyles.optionList}
                    >
                      {option.productOptionValues.map(value =>
                        <ToggleButton
                          key={value.id}
                          value={value.id}
                          aria-label="left aligned"
                          style={ productOptions[optionIndex].selected === value.id 
                            ? { backgroundColor: '#173300', color: '#fff' }
                            : {}
                          }
                        >
                          {value.name}
                        </ToggleButton>
                      )}
                    </ToggleButtonGroup>
                    <hr className={ProductStyles.divider}/>
                  </React.Fragment>
                )}
              </div>
            }
            { productExpandInfo && productExpandInfo[0]?.title &&
              <div style={{ borderRadius: 8, marginTop: 0, width: '100%' }}>
                {productExpandInfo.map((item, index) => {
                  return (
                    <div key={index} onClick={() => toggleMoreInfo(index)}>
                      <div className={ProductStyles.titleMoreInfo}>
                        <h2>{item.title.toLowerCase()}</h2>
                        { indexInfo === index ? <ChevronUp /> : <ChevronDown />}  
                      </div>
                      
                      { indexInfo === index &&
                        <p className={ProductStyles.desMoreInfo}>{item.description}</p>
                      }
                       <hr className={ProductStyles.divider} style={{ marginTop: indexInfo !== index && 0 }}/>
                    </div>
                  )
                    } )
                  }
              </div>
            }
            { productFiles &&
              <div className={ProductStyles.filesSection}>
                <div className={ProductStyles.filesContainer}>
                  <Donwload />
                  <Link style={{ margin: 5 }} target="_blank" href={process.env.REACT_APP_S3_URL + productFiles.fileUrl}>Ficha técnica</Link>
                </div>
              </div>
            }
            <Button
              disabled={!isValid || loading || outOfStock || !IsActive}
              className={ProductStyles.addToCartButton}
              variant="contained"
              fullWidth
              color="primary"
              onClick={handleHaveStore}
              startIcon={<IconAddCart fontSize="small"/>}>
              <span onClick={handleButtonDisabledScroll}>Agregar al carrito</span>
            </Button>
          </aside>
          <Backdrop style={{ zIndex: 1400 }} open={beforeCartLoading}>
            <CircularProgress />
          </Backdrop>
        </section>
      </article>
    </main>
  )
}

const mapStateToProps = ({ main }) => ({
  items: main.items,
  store: main.store
});

const mapDispatchToProps = dispatch => bindActionCreators({ setCartState, setStoreState }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Product);