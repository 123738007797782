// ReactJS
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom'

// External Dependencies
import axios from 'axios'
import { useForm } from "react-hook-form"

// Icons [PNG]
import exmpleimg from '../../assets/images/emptyCart.png'

// Styles
import SendOrderStyles from '../../components/SendOrder/SendOrder.module.scss'

// External dependencies
import {  Button, IconButton } from '@material-ui/core'

//Pixel FACEBOOK
import ReactPixel from 'react-facebook-pixel'
import ChevronLeft from '../Icons/ChevronLeft';
import CustomFormFiels from '../FormFields/FormFields';



const SendOnlineOrder = ({ items, store }) => {
    const [code, setCode] = useState(JSON.parse(localStorage.getItem("StepData"))?.code || '57')
    const [allItems, setAllItems] = useState([])
    const [fieldsForm, setFieldsForm] = useState(null)
    const [opcQuestions, setOpcQuestions] = useState({})
    const [total] = useState(localStorage.getItem('totalForProps'))
    
    const { register, setValue, handleSubmit, watch, formState: { isValid } } = useForm({
        mode: 'onChange',
    })
    const history = useHistory()
    const { /* storeUrl, */ phoneNumber } = useParams()
    const userStore = store?.userStores?.find(uStore => uStore.mobilePhoneNumber === phoneNumber)
    const orderIdExist = localStorage.getItem('orderId')
    const goBack = () => history.push(`/${phoneNumber}/order/products`)

    const saveInfoAd = (dataForm, orderResId) => {
        const adiData = {
          customer: {
            dni: dataForm.numero_de_documento ? dataForm.numero_de_documento : '',
            email: dataForm.Email ? dataForm.Email : '',
            document_type: dataForm.tipo_de_documento ? dataForm.tipo_de_documento : '',
            phone_number: `${code}${dataForm.phone}`
          }
        }
        axios.put(`${process.env.REACT_APP_API_URL}/wa10x/stores/${store.id}/orders/${orderResId}/customer-info`, adiData)
        .then(res => console.log(res))
        .catch( err => console.log(err))
      }

    const onSubmit = (data) => {
        localStorage.setItem("Step", 2)

        localStorage.setItem("StepData", JSON.stringify(data))
        if(orderIdExist) {
            history.push(`/checkout/${store.id}/${orderIdExist}`, {
                phoneNumber: phoneNumber
            })
        }else{
            GoToCheckout(data) 
        } 
    }

    const GoToCheckout = (formData) => {
        // console.log(formData, fieldsForm)
          axios.post(`${process.env.REACT_APP_API_URL}/wa10x/${store.id}/${phoneNumber}/order`, {
            order_details: allItems.map(item => ({
            product_id: item.id,
            price: item.productListDiscountPrice || item.price,
            quantity: item.amount,
            options: item.productOptions.map(option => +option.selected),
            ...item.variantId && { product_variant_id: +item.variantId }
            })),
            comments: 'Carrito abandonado',//TODO: validations
            customer: {
            first_name: formData.name,
            phone_number: `${code}${formData.phone}`,
            },
            user_store_id: +userStore.id,
            direct_sale: userStore.directSaleEnabled
        })
        .then((orderRes) => {
            if(opcQuestions?.dni || opcQuestions?.email || opcQuestions?.documen_type) {
                saveInfoAd(formData, orderRes.data.id)
            }
            if(!formData){
                history.push(`/checkout/${store.id}/${orderRes.data.id}`, {
                    phoneNumber: phoneNumber
                })
                ReactPixel.track('Lead', {
                content_name: 'pago online',
                value: total,
                currency: store.currency,
                })
            }else{
                const arrToString = arr => arr.join(', ')
                const objTostring = obj => JSON.stringify(obj)
                const mappedResponses = fieldsForm.map( resField => ({
                    form_id: resField.form_id,
                    form_field_id: resField.id,
                    type: resField.type,
                    title: resField.title,
                    response: resField.type === 'checkbox' && arrToString(formData[resField.title]) || resField.type === 'range' && objTostring(formData[resField.title]) || formData[resField.title],
                    order_id: orderRes.data.id
                }) )
                
                axios.post(`${process.env.REACT_APP_API_URL}/stores/${store.id}/orders/${orderRes.data.id}/dynamic-form-responses`, mappedResponses)
                .then((res) => {
                    history.push(`/checkout/${store.id}/${orderRes.data.id}`, {
                        phoneNumber: phoneNumber
                    })
                    ReactPixel.track('Lead', {
                        content_name: 'pago online',
                        value: total,
                        currency: store.currency,
                    })
                    ReactPixel.track('Purchase', {
                        content_type: 'checkout',
                        num_items: items.length,           
                    })
                })
                .catch( err => console.log(err))
            }
        })
    } 

    useEffect(() => {
        const localAllItems = items.filter(item => item.amount !== 0)
    
        /* if (localAllItems.length === 0) {
          history.push('/')
        } */
    
        items.forEach(item => {
          item.extraVariants.forEach(variant => {
            localAllItems.push({
              sku: variant.sku,
              name: item.name,
              id: item.id,
              price: variant.price || item.price,
              amount: variant.amount,
              productOptions: variant.options.map(option => ({
                selected: option,
                productOptionValues: item.productOptions.find(
                  productOption => productOption.productOptionValues
                    .map(pov => pov.id)
                    .indexOf(option) !== -1
                ).productOptionValues
              })),
              variantId: variant.variantId
            })
          })
        });
    
        const compare = (a, b) => {
          if (a.id < b.id) return -1
          if (a.id > b.id) return 1
          return 0;
        }
    
        setAllItems(localAllItems.sort(compare))

        const savedFormData = JSON.parse(localStorage.getItem('StepData'));
        if (savedFormData) {
        Object.keys(savedFormData).forEach(key => {
            setValue(key, savedFormData[key])
        })
        }
      }, [])

    //Facebook pixel
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            store?.facebookPixelCode && ReactPixel.init(store?.facebookPixelCode);
            store?.facebookPixelCode && ReactPixel.track('InitiateCheckout', {
                content_type: 'step data',
            } )
        }

        // setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/stores/${store.id}/dynamic-form`)
        .then(res => {
          const { data } = res
          const { fields } = data
    
          setFieldsForm(fields)
          setOpcQuestions({
            aditional_shipping_details: data.aditional_shipping_details,
            dni: data.dni,
            documen_type: data.documen_type,
            email: data.email
          })
        //   setLoading(false)
        })
        // .catch(err => setLoading(false))
  }, [store])
 

  return (
        <main className={SendOrderStyles.sendOrderContainer}>
            <figure>
                <IconButton
                    onClick={goBack}
                    color="inherit"
                    aria-label="regresar"
                >
                    <ChevronLeft />
                </IconButton>
            </figure>
            <article className={SendOrderStyles.mainContainer}>
                <aside className={SendOrderStyles.stepsContainer}>
                <h1>Completa los campos para finalizar tu pedido</h1>
                <section className={SendOrderStyles.steps}>
                    <span className={SendOrderStyles.generalStepOne}>
                    <img src={exmpleimg} alt='step 1 image'/>
                    </span>
                    <p className={SendOrderStyles.tagStepOne}>Datos generales</p>
                </section>
                <hr className={SendOrderStyles.dividerStepOne}/>
                <section className={SendOrderStyles.steps}>
                    <span >
                        <img src={exmpleimg} alt='step 2 image'/>
                    </span>
                    <p className={SendOrderStyles.tagStepTwo}>Datos de envío</p>
                </section>
                <hr className={SendOrderStyles.dividerStepOne}/>
                <section className={SendOrderStyles.steps}>
                    <span >
                        <img src={exmpleimg} alt='step 2 image'/>
                    </span>
                    <p className={SendOrderStyles.tagStepTwo}>Pago</p>
                </section>
                </aside>
                <form onSubmit={handleSubmit(onSubmit)} className={SendOrderStyles.formContainer}>
                    <aside >
                        <CustomFormFiels 
                            register={register}
                            code={code}
                            setCode={setCode}
                            opcQuestions={opcQuestions}
                            fieldsForm={fieldsForm}
                            watch={watch}
                        />
                        <div className={SendOrderStyles.buttonsContainer}>
                            < Button
                                disabled={isValid ? false : true}
                                type='submit'
                                className={SendOrderStyles.sendOrderBtn}
                                variant="contained"
                            >
                                Continuar
                            </Button>
                        </div>
                    </aside>
                </form>
            </article>
        </main>
        
    );
}

const mapStateToProps = ({ main }) => ({
    items: main.items,
    store: main.store,
  })
  
  export default connect(
        mapStateToProps
    )(SendOnlineOrder)